var webserverRoot = window.location.protocol + "//" +window.location.hostname + process.env.PATH_PREFIX + "Cention/web/";
var oldWebRoot = location.protocol + '//' + location.hostname;
import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import update from 'react-addons-update';
//dev component
import Ckeditor from '../components/Ckeditor';
import LightCkeditor from '../components/LightCkeditor';
import CentionPopup from '../components/CentionPopup';
import DropDown from '../components/DropDown';
import ErrandActionTools from '../components/ErrandActionTools';
import ErrandHelper from '../components/ErrandHelper';
import AnswerReplyOptions from '../components/AnswerReplyOptions';
import CentionHotKeys from '../components/CentionHotKeys';
import OtherContacts from '../components/OtherContacts';
import AutoCompleter from '../components/AutoCompleter';
import DrilldownDropdown from '../components/DrilldownDropdown';
import ErrandThread from '../components/ErrandThread';
import ErrandThreadHistory from '../components/ErrandThreadHistory';
import ErrandThreadCheckbox from '../components/ErrandThreadCheckbox';
import ErrandTranslation from '../components/ErrandTranslation';
import TranslationResultBox from '../components/TranslationResultBox';
import Calendar from '../components/Calender';
import FileUploader from '../components/FileUploader';
import ImageButton from '../components/ImageButton';
import LibrarySearch from '../components/LibrarySearch';
import AgentAttachment from '../components/AgentAttachment';
//FLUX actions and store
import WorkflowActions from '../flux/actions/workflowActions';
import WorkflowStore from '../flux/stores/workflowStore';
import WorkflowErrandActions from '../flux/actions/workflowErrandActions';
import WorkflowErrandStore from '../flux/stores/workflowErrandStore';

//feature
import Feature from '../components/feature';

var mechLINE = {
	id: 5,
	idstr: "5",
	name: I("LINE (manual)"),
	manualCtx: "Manual",
	abbr: "LINE",
	type: Workflow.Errand.SERVICE_MANUAL_LINE
};
var mechVK = {
	id: 7,
	idstr: "7",
	name: I("VKontakte (manual)"),
	manualCtx: "VKontakte",
	abbr: "VK",
	type: Workflow.Errand.SERVICE_MANUAL_VKONTAKTE
};
var manualFBChannel = {
	id: 2,
	idstr: "2",
	name: I("Facebook (manual)"),
	manualCtx: "Facebook",
	abbr: "Facebook",
	type: Workflow.Errand.SERVICE_MANUAL_FACEBOOK
};
var manualTwitter = {
	id: 8,
	idstr: "8",
	name: I("Twitter (manual)"),
	manualCtx: "Twitter",
	abbr: "Twitter",
	type: Workflow.Errand.SERVICE_MANUAL_TWITTER
};
var ErrandOwnerNotification = createReactClass({
	getInitialState: function(){
		return{
			errandNotificationMessage: this.ownerTakeErrand,
			msgClass: "owned",
		}
	},
	manualErrand: I("Starting errand."),
	ownerTakeErrand: I("You have taken ownership of this errand. It will now appear in My Errands."),
	errandOwnedPickAnother: I("This errand is owned by {0}, click here to pick another errand"),
	errandOwnedBackToInbox: I("This errand is owned by {0}, click here to return to the inbox"),
	errandUnhandled: I('You have opened an unhandled errand owned by {0}.'),
	errandOpenFromFolder: I('This errand has been opened from folder {0} and it is owned by {1}.'),
	errandFromSearchAnswer: I('You have opened this errand from Search Answers.'),
	errandAlreadyHandledBy: I('This errand has already been handled by {0}.'),
	checkingIfErrandTaken: I("Checking to see if the errand has been taken"),
	returnToPrevious: function(){
		window.location.hash = ErrandHelper.getCacheBackTo();
	},
	returnToInbox: function(){
		window.location.hash = 'new';
	},
	componentWillMount: function(){
		var errand = this.props.errand;
		var acquireErrand = this.props.acquireErrand;

		if( this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_NEW || this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH )
		{
			if( acquireErrand.acquired ){
				if( errand.data.closed || acquireErrand.data.deleted ){
					this.state.errandNotificationMessage = this.errandAlreadyHandledBy.format(acquireErrand.owner.name);
					this.state.msgClass  = "handled";
				}else{
					this.state.errandNotificationMessage = this.ownerTakeErrand;
					this.state.msgClass = "owned";
				}
			}else{
				if(this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_NEW){
					var AgentCurrentStatus = "";
					if( $("li#status > a span:nth-child(1)").length > 0)
						AgentCurrentStatus = $("li#status > a span:nth-child(1)").text();
					this.state.msgClass = "acquired";
					if(this.props.wfSettings.AutoPickUpNextIsEnabled && AgentCurrentStatus == 'Available'){
						this.state.errandNotificationMessage = <span style={{cursor: 'pointer'}} onClick={this.returnToPrevious}>{this.errandOwnedPickAnother.format(acquireErrand.owner.name)}</span>;
					}else if( !acquireErrand.acquired && this.props.wfSettings.onlyShowErrandIfAcquired ){
						this.state.errandNotificationMessage = this.checkingIfErrandTaken;
						this.state.msgClass = "acquired";
					}else{
						this.state.errandNotificationMessage = <span style={{cursor: 'pointer'}} onClick={this.returnToInbox}>{this.errandOwnedBackToInbox.format(acquireErrand.owner.name)}</span>;
					}
				}else if( errand.data.closed || acquireErrand.data.deleted ){
					this.state.errandNotificationMessage = this.errandAlreadyHandledBy.format(acquireErrand.owner.name);
					this.state.msgClass  = "handled";
				}
				else{
					this.state.errandNotificationMessage = this.errandUnhandled.format(acquireErrand.owner.name);
					this.state.msgClass = "acquired";
				}
			}
		}else if( this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_MY ){
			var folder = I('My Errands');
			this.state.errandNotificationMessage = this.errandOpenFromFolder.format(folder, (acquireErrand.owner.id == this.props.wfSettings.activeUserId ? I("You") : acquireErrand.owner.name));
			this.state.msgClass = (acquireErrand.owner.id == this.props.wfSettings.activeUserId ? "owned" : "acquired");
		}else if(this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH_ANSWER){
			this.state.errandNotificationMessage = this.errandFromSearchAnswer;
			this.state.msgClass  = "acquired";
		}else if( this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH && (errand.data.closed || acquireErrand.data.deleted )){
			this.state.errandNotificationMessage = this.errandAlreadyHandledBy.format(acquireErrand.owner.name);
			this.state.msgClass  = "handled";
		}else if(this.props.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ){
			this.state.errandNotificationMessage = this.manualErrand;
			this.state.msgClass  = "owned";
		}
		else{
			//Nothings
		}
		this.setState(this.state);
	},
	componentWillReceiveProps: function(nextProps) {
		if (this.props.errandSrcContext != nextProps.errandSrcContext) {
			if(nextProps.errandSrcContext == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ){
				this.setState({
					errandNotificationMessage: this.manualErrand,
					msgClass: "owned"
				});
			}
		}
	},
	render: function(){
		return(<div className="errand-notification" id="ErrandOwner">
			<p className={this.state.msgClass} style={{backgroundColor: this.state.msgColor}}>{this.state.errandNotificationMessage}</p>
			</div>
		)
	}
});
var SearchAutoCompleteAction = createReactClass({
	handleInsertAnswer: function(e){
		this.props.onInsertAnswer(this.props.item);
	},
	handleAppendAnswer: function(e){
		this.props.onAppendAnswer(this.props.item);
	},
	render: function() {
		return (
		<div className="btn-group btn-group-xs pull-right" role="group" aria-label="...">
			<button id={this.props.item[this.props.fields.id]} type="button" className="btn btn-xs btn-default" data-toggle="tooltip" title={I("Insert answer")} onClick={this.handleInsertAnswer}><i className="fa fa-comment"></i></button>
			<button id={this.props.item[this.props.fields.id]} type="button" className="btn btn-xs btn-default" data-toggle="tooltip" title={I("Append answer")} onClick={this.handleAppendAnswer}><i className="fa fa-comments-o"></i></button>
		</div>
		);
	}
});
var NicePopover = createReactClass({
	displayName: "NicePopover",
	appear: false,
	id: "",
	ellipsisStyle: {
		verticalAlign: "bottom",
		display: "inline-block",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "90%"
	},
	getDefaultProps: function() {
		return {
			pos: "top"
		};
	},
	getInitialState: function() {
		this.id = this.props.keyName + "NicePopover";
		return {};
	},
	componentWillMount: function() {},
	componentDidMount: function() {
		$("body").on("click.NicePopover", function(e) {
			if(this.appear) {
				var hide = false;
				var allpop = $("[id$=NicePopover]");
				var t = e.target.parentNode;
				allpop.each(function() {
					if(!$(this).is(t) && $(this).has(t).length === 0 &&
						$('.popover').has(t).length === 0) {
						hide = true;
						return false;
					}
				});
				if(hide) {
					this.hide();
					if($('#'+this.id).is(t) || !allpop.is(t) && !$(e.target).hasClass("underlink")) {
						// this allow clicking other similar elements also trigger
						// popover at the same time shutdown the previous popover.
						e.stopPropagation();
					}
				}
			}
		}.bind(this));
	},
	componentWillUnmount: function() {
		$("body").off("click.NicePopover");
	},
	hide: function() {
		$('#'+this.id).popover("destroy");
		$('#'+this.id).attr('title', this.props.txt);
		this.appear = false;
	},
	togglePop: function(e) {
		if(!this.appear) {
			$('#'+this.id).popover(this.popoverOpt);
			$('#'+this.id).popover("show");
			this.appear = true;
		}
	},
	normTemplate: '<div class="popover" role="tooltip"> \
		<div class="arrow"></div><div class="popover-content"></div></div>',
	ovfyTemplate: '<div class="popover" role="tooltip"> \
		<div class="arrow"></div><div class="popover-content" \
		style="max-height: 250px; overflow-y: auto;"></div></div>',
	render: function() {
		var htmltxt = this.props.txt;
		var reAbbr = /^<abbr title=\"(.*?)\"\s?>.*<\/abbr>$/.exec(htmltxt);
		var textOverflow = false;
		if(reAbbr && reAbbr.length > 1) {
			htmltxt = reAbbr[1];
		}
		if(!this.props.txt || htmltxt == "") {
			return (
				<span style={this.ellipsisStyle}>
					<span className="dataTitle">{this.props.hdr} : </span>
				</span>
			);
		}
		var template;
		if(this.props.pos == "bottom" || this.props.type == "icon") {
			template = this.normTemplate;
		} else {
			template = this.ovfyTemplate;
		}
		this.popoverOpt = {
			content: htmltxt,
			placement: this.props.pos,
			trigger: "manual",
			template: template
		};
		if(this.props.type == "icon") {
			return (
				<span role="button" id={this.id} title={htmltxt}
					onClick={this.togglePop}>
					&nbsp;<i className={this.props.iconClass} style={this.props.customStyle ? this.props.customStyle : ""} title={htmltxt}></i>
				</span>
			);
		}else {
			return (
				<span style={this.ellipsisStyle} id={this.id} title={htmltxt}>
					<span className="dataTitle">{this.props.hdr} :&nbsp;</span>
					<span role="button" onClick={this.togglePop}>{htmltxt}
						</span>
				</span>
			);
		}
	}
});
var ContactCardBox = createReactClass({
	getInitialState: function() {
		return{
			contactCardAvailable: false,
			notesAvailable: false,
			channels: [],
			custNotesCount: 0,
			clientContactCount: 0
		}
	},
	componentDidMount: function(){
		if((this.props.errandSrc != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL)
			|| (this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.props.externalManual == true)){
			this.checkChannels();
		}
	},
	componentWillReceiveProps: function(nextProps){
		this.setState({
			custNotesCount:nextProps.custNotesCount,
			notesAvailable: (nextProps.custNotesCount > 0 ? true : false),
			clientContactCount: nextProps.clientContactCount,
			contactCardAvailable: (nextProps.clientContactCount > 0 ? true : false)
		});
	},
	checkChannels: function(){
		var channels = this.props.channels;
		var contacts = JSON.parse(channels);
		var contactCardAvailable = false;
		if(contacts.client && contacts.client.length > 0 && F("agent.connect-address-to-clients")){
			contactCardAvailable = true;
		}else{
			contactCardAvailable = false;
		}
		this.setState({
			contactCardAvailable: contactCardAvailable,
			custNotesCount: this.props.custNotesCount,
			notesAvailable: this.props.custNotesCount > 0 ? true : false
		});
	},
	render: function(){
		var contactCardActivated = false;
		if(this.state.contactCardAvailable || this.state.notesAvailable){
			contactCardActivated = true;
		}else{
			contactCardActivated = false;
		}
		return(
			<div className="btn-group" id="CustomerNotes">
				<span className={contactCardActivated ? 'contactCardIcon active' : 'contactCardIcon'} title={I("Contact card")} id="ContactCard" onClick={this.props.handleContactCard} data-action="" data-cancel=""></span>
				<span className="badge-cust-notes-counter">{this.state.custNotesCount}</span>
			</div>
			)
	}
});
var ChannelSelection = createReactClass({
	getInitialState: function() {
		return{
			contactCardAvailable: false,
			channels: [],
			selectedChannel: "",
			changeChannelFeature: false,
		}
	},
	componentWillMount: function() {
		this.state.changeChannelFeature = F("change-channel");
		if(this.props.errandSrc != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			this.state.selectedChannel = (this.props.currentReplyChannel ? this.props.currentReplyChannel : this.props.serviceName);
			this.loadSelectedChannels();
		} else {
			if(this.props.errandContextType == "Manual") {
				var selectedChannels = [{id: 1, value: I("Email (manual)")}];
				var currentChannel = "";
				if (typeof this.props.serviceName !== 'undefined') {
					currentChannel = this.props.serviceName.toLowerCase();
				}
				/*
				//FIXME(Mujibur): For V4.4 we have to keep this feature.
				if( this.props.wfSettings.manualFBChannel != undefined && this.props.wfSettings.manualFBChannel )
					selectedChannels.push({id: 2, value: I("Facebook(PM)")});
				*/
				if(this.props.wfSettings.enableSMS != undefined && this.props.wfSettings.enableSMS)
					selectedChannels.push({id: 3, value: I("SMS (manual)")});
				if(this.props.area.lineAccounts != undefined && this.props.area.lineAccounts.length > 0)
					selectedChannels.push({id: mechLINE.id, value: mechLINE.name});
				this.state.channels = selectedChannels;
				//Mujibur:(2016-10-21) When we want to create a manual errands from `Start Errand` menu item
				// Email(Manual) will be selected by defaults
				this.state.selectedChannel = "1";
				if(this.props.wfSettings.manualVoice != undefined &&
					this.props.wfSettings.manualVoice &&
					this.props.externalManual) {
					selectedChannels.push({id: 6, value: I("Voice (manual)")});
					if (currentChannel == "voice"){
						this.state.selectedChannel = "6";
					}
				}
				this.setState(this.state);
				this.props.onChange( this.state.selectedChannel );
			} else if(this.props.errandContextType == manualFBChannel.manualCtx) {
				this.props.onChange(manualFBChannel.idstr);
			} else if(this.props.errandContextType == mechVK.manualCtx) {
				this.props.onChange(mechVK.idstr);
			} else if(this.props.errandContextType == manualTwitter.manualCtx){
				this.props.onChange(manualTwitter.idstr);
			}
		}
	},
	loadSelectedChannels: function(){
		var channelsObj = this.props.channels;
		var channelsParsed = JSON.parse(channelsObj);
		if(typeof channelsParsed.error === 'string') {
			console.debug("client address error: ", channelsParsed.error);
			this.setState({channels: []});
			return;
		}
		var channels = channelsParsed["client"];
		var currentChannel = this.props.serviceName.toLowerCase();
		var selectedChannels = [];
		channels.map(function(item,i){
			var channelName = item.value.substr(0, item.value.indexOf('-'));
			var channelText = channelName.replace(/ /g,'').toLowerCase();
			switch (currentChannel){
				case "sms":
					if(channelText == "email" || channelText == "sms"){
						selectedChannels.push({value:item.value, id:item.id});
					}
					break;
				case "email":
					if(channelText == "email" || channelText == "sms"){
						selectedChannels.push({value:item.value, id:item.id});
					}
					break;
				case "facebook":
					if(channelText == "facebook" || channelText == "sms" || channelText == "email"){
						selectedChannels.push({value:item.value, id:item.id});
					}
					break;
				case "twitter":
					if(channelText == "email" || channelText == "sms"){
						selectedChannels.push({value:item.value, id:item.id});
					}
					break;
				case "linkedin":
					if(channelText == "email" || channelText == "sms"){
						selectedChannels.push({value:item.value, id:item.id});
					}
					break;
				case "voice":
					if(channelText == "voice"){
						selectedChannels.push({value:item.value, id:item.id});
					}
					break;
			}
			this.state.channels = selectedChannels;
			this.setState(this.state);
		}.bind(this));
	},
	render: function() {
		if((this.state.changeChannelFeature && this.state.channels.length > 0 &&
			this.props.errandSrc != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) ||
			(this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.props.errandContextType == "Manual")) {
			var chs = this.state.channels;
			var selected = this.props.currentReplyChannel ? this.props.currentReplyChannel : (this.props.serviceName ? this.props.serviceName : this.state.selectedChannel);
			var currentTitle = (this.props.serviceName ? this.props.serviceName : this.state.selectedChannel);
			if(this.props.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL && this.props.errandContextType == "Manual") {
				// this portion is needed to trigger Area switching during manual errand where some areas allow certain
				// services (channel) while others not.
				var channels = [];
				
				channels.push({id: 1, value: I("Email (manual)")});
					if(this.props.wfSettings.enableSMS != undefined && this.props.wfSettings.enableSMS)
						channels.push({id: 3, value: I("SMS (manual)")});
					if(this.props.area.lineAccounts != undefined && this.props.area.lineAccounts.length > 0)
						channels.push({id: mechLINE.id, value: mechLINE.name});
					if(this.props.wfSettings.manualVoice != undefined &&
						this.props.wfSettings.manualVoice &&
						this.props.externalManual) {
						channels.push({id: 6, value: I("Voice (manual)")});
					}
			
				// TODO: future channel(s)
				chs = channels;
				var i=0;
				for(i=0;i < channels.length;i++) {
					if(selected == channels[i].id.toString()) {
						break;
					}
				}
				if(this.props.wfSettings.manualVoice != undefined &&
					this.props.wfSettings.manualVoice &&
					this.props.externalManual) {
					selected = "6";
				} else if(i == channels.length) {
					selected = "1";
				}
				currentTitle = channels[0].value; // this does not seem useful
			}
			var triggerOnChangeForNewSelectedItems = (this.props.errandContextType == "Manual"
				? true
				: false);
			return(
				<span style={{display: 'inline'}} key="errandChooseReplyChannel">
					<DropDown
						id="ChooseReplyChannel"
						title={I('Please choose the channel you want to send the answer to.')}
						selectedItems={selected}
						currentTitle={currentTitle}
						items={chs}
						fields={{id: "id", name: "value"}}
						name={I("Channel")}
						textNoItem={I("No Channel")}
						textChooseItem={I("Choose channel")}
						textNoItemSelected={I("No Channel selected")}
						textSelectedItem={I("Selected channel")}
						multiSelect={false}
						wantSelectAll={false}
						wantSelectNone={false}
						singleSelect={true}
						align="left"
						onChange={this.props.onChange}
						triggerOnChangeForNewSelectedItems={triggerOnChangeForNewSelectedItems}
						type="link"
						class="fourthColumn" />
				</span>
				)
		} else {
			var serviceName = this.props.serviceName;
			if(this.props.errandContextType == "Facebook") {
				serviceName = I("Facebook (manual)");
			} else if(this.props.errandContextType == mechVK.manualCtx) {
				serviceName = mechVK.name;
			}else if(this.props.errandContextType == manualTwitter.manualCtx) {
				serviceName = manualTwitter.name;
			}
			return(
				<p className="fourthColumn" key="errandReplyChannel">
					<span className="dataTitle">{I("CHANNEL")} : </span>
					<span>{serviceName}</span>
				</p>
			)
		}
	}
});
var styleDsply = {display: "block"};
var styleHide = {display: "none"};
var ErrandForm = createReactClass({
	ansCkePlainTxt: "",
	changeKey: 0,
	extQueueErrandTagDone: false,
	workflowSuccessfullyMounted: false,
	getInitialState: function(){
		return{
			visibleAttachmentBox: false,
			visibleTranslationBox: false,
			questionEditable: false,
			answerBody:"",
			currentAreaData: {},
			currentAreaDatas: {},
			eeLightsOn: "deactivate",
			areasForSearchAnswer: "",
			areasForErrand: "",
			changeArea: false,
			selectedTags: [],
			targetModal: "",
			associated_list: [],
			currentReplyChannel: "",
			channels: [],
			currentErrandType: "",
			currentErrandService: 0, // this is int type
			selectedSalutations: 0,
			selectedSignature: 0,
			selectedReplyAddress: [],
			selectedReplyAddressCC: [],
			selectedReplyAddressBCC: [],
			lockReplyTo: false,
			visibleTranslationBoxClass: "",
			editQuestionClass: "",
			selectedReplyAddressForwardExternal: [],
			visibleChangeRecepient: true,
			hotkeys: false,
			hotCtrlKeys: "",
			hotKeysAvailable: ['0','1','2','3','4','9','H','A','B','C','D','E','F','I','O','S'],
			priority: false,
			partialAnswer: false,
			lockToMe: false,
			librarySuggestion: false,
			includeQuestion: false,
			includeQuestionDefaultNo: false,
			errandDueDate: ErrandHelper.DATE_NOT_SET,
			excerpt_more: "",
			excerpt_full: "",
			uploadedAttachments: [],
			archiveAttachments: [],
			libraryAttachments: [],
			questionAttachments: [],
			activateForwardExternal: false,
			errandHistoryThread: [],
			contactCardAvailable: false,
			errandNotesCount: 0,
			custNotesCount: 0,
			clientContactCount:0,
			externalManual: false,
			ctx: WorkflowErrandStore.getState().ctx,
			translateFromLang: '',
			translateToLang: '',
			selectedSocialMedia: "",
			openedByExternal: false,
			isClearIT: false,
			clientContactDetails: [],
			forceLoadedArea: false,
			initiallyToggleDropdown: false,
			clickSendCaptured: false,
			errorMessageSms: "",
			errorMessageCc : "",
			errorMessageTo : "",
			errorMessageBcc : "",
			errorMessageEe : "",
			showTranslationRes: false,
			toggleSavedAttachments: true,
			toggleActionElements: true,
			lockTransaction: false,
			isSticky: null
		}
	},
	getDefaultProps: function () {
		return {
				OPTIONPLAINTEXT: 		1,
				OPTIONHTMLCOMPONENTS: 	2,
				SUBOPTIONTEXTFIELD: 	1,
				SUBOPTIONTEXTAREA:	2,
				SUBOPTIONSELECT: 		3,
				SUBOPTIONCHECKBOX: 	4,
				SUBOPTIONRADIO: 		5,
				SUBOPTIONBUTTON: 	6
			};
	},
	componentDidUpdate: function(prevProps, prevState) {
		if(prevProps.newErrandData != this.props.newErrandData) {
			var acq = this.props.errandInfo.acquireErrand;
			if(acq && acq.data) {
				var s = "";
				if(acq.data.answer_subject) {
					s = acq.data.answer_subject;
				} else if(acq.data.subject) {
					s = acq.data.subject;
				} else {
					//s = I("No Subject");
				}
				this.refs.subject.SetValue(removePM(s));
			}
		}
		if (prevProps.areaData != this.props.areaData ||
			(this.props.openedByExternal == true &&
			this.extQueueErrandTagDone == false)) {
			if (this.state.currentAreaData) {
				var tags = [];
				if (this.state.currentAreaData.normal_tags != null) {
					tags = this.state.currentAreaData.normal_tags.concat(this.state.currentAreaData.delete_tags);
				} else {
					tags = this.state.currentAreaData.delete_tags
				}
				if(tags != null && tags.length > 0) {
					this.generateSelectedTags(tags);
				}
			}
		}
		if(prevProps.areaData != this.props.areaData || prevState.currentAreaData != this.state.currentAreaData) {
			this.setDefaultPersonalisation();
		}
		if(prevProps.errandInfo != this.props.errandInfo) {
			this.externalExpertAlerts(this.props.errandInfo.extendedData);
		}

	},
	componentWillReceiveProps: function(nextProps) {
		var s = {};
		if(($.isEmptyObject(this.props.currentErrandData) == false) &&
			(this.props.currentErrandData != nextProps.currentErrandData)){
			s.areasForSearchAnswer = this.props.currentErrandData.data.area.toString();
			s.areasForErrand = nextProps.currentErrandData.data.area.toString();
			s.currentAreaDatas = this.props.areaData;
			s.currentAreaData = nextProps.areaData;
		}
		if(nextProps.errandInfo.extendedData.data){
			s.errandNotesCount = nextProps.errandInfo.extendedData.data.errand_notes;
			s.custNotesCount = nextProps.errandInfo.extendedData.data.client_notes;
			if((typeof nextProps.errandInfo.extendedData.data.errand_target_area_id !== "undefined") &&
				(nextProps.errandInfo.extendedData.data.errand_target_area_id != null))
			{
				s.areasForErrand = nextProps.errandInfo.extendedData.data.errand_target_area_id;
			}
		}
		if($.isEmptyObject(this.props.currentErrandData) == false){
			s.areasForErrand = nextProps.currentErrandData.data.area.toString();
		}
		if(nextProps.clientContactDetails != undefined ){
			s.clientContactDetails = nextProps.clientContactDetails;
		}
		if(typeof nextProps.externalManual !== "undefined"){
			s.externalManual = nextProps.externalManual;
		}
		if(nextProps.newErrandData != this.props.newErrandData) {
			s.answerBody = (nextProps.errandInfo &&
				nextProps.errandInfo.acquireErrand &&
				nextProps.errandInfo.acquireErrand.data ?
				nextProps.errandInfo.acquireErrand.data.answer_body : "");
			this.updateSavedAttachment(nextProps);
			s.questionBody = (nextProps.errandInfo &&
				nextProps.errandInfo.acquireErrand &&
				nextProps.errandInfo.acquireErrand.data ?
				nextProps.errandInfo.acquireErrand.data.question_body : "");
			this.updateService(nextProps);
			this.updateAddresses(nextProps);
		}
		if(nextProps.channelList) {
			var contacts = JSON.parse(nextProps.channelList);
			if(contacts.client) {
				s.clientContactCount = contacts.client.length;
			}else {
				s.clientContactCount = 0;
			}
		}
		if(nextProps.currentContextType != this.props.currentContextType){
			s.currentReplyChannel = this.setCurrentReplyChannel(nextProps.currentContextType);
		}
		this.setState(s);
	},
	setCurrentReplyChannel: function( ctx ){
		var curCtx = "1"; //default Manual errand
		switch( ctx ){
			case mechVK.manualCtx:
				curCtx = mechVK.idstr;
				break;
			case manualFBChannel.manualCtx:
				curCtx = manualFBChannel.idstr;
				break;
			case manualTwitter.manualCtx:
				curCtx = manualTwitter.idstr;
				break;
		}
		return curCtx;
	},
	componentWillMount: function() {
		if (F('hotkeys')) {
			this.setErrandHotkeys(F('hotkeyControls'));
			this.setState({
				hotkeys: true,
			    hotCtrlKeys: F('hotkeyControls')
			});
		}
		if ($.isEmptyObject(this.props.currentErrandData) == false &&
			this.props.currentErrandData != null && (typeof this.props.externalManual === "undefined" ||
			!this.props.externalManual)) {
			this.updateSavedAttachment(this.props);
			this.updateService(this.props);
			this.updateAddresses(this.props);
			this.setState({
				areasForSearchAnswer: this.props.currentErrandData.data.area.toString(),
				areasForErrand: this.props.currentErrandData.data.area.toString(),
				currentAreaData: this.props.areaData
			});
			if( F('check-membership-status') && F('check-membership-status-url') ) {
				this.state.membershipStatus = this.handleCheckingMembership(this.props.currentErrandData.data.fromAddress ? this.props.currentErrandData.data.fromAddress : "", F('check-membership-status-url'));
			}
		}
		if(this.props.openedByExternal == true || this.props.externalManual == true) {
			this.setDefaultPersonalisation();
		}
	},
	componentDidMount: function(){
		this.workflowSuccessfullyMounted = true;
		this.setDefaultPersonalisation();
		this.externalExpertAlerts(this.props.errandInfo.extendedData);
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('scroll', this.handleScrollAndSetSticky);
	},
	componentWillUnmount: function(){
		this.state.ctx.ErrandAnswerUpdated = false;
		$('.overlay').remove();
		this.workflowSuccessfullyMounted = false;
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('scroll', this.handleScrollAndSetSticky);
	},
	handleResize: function(e) {
		const winHeight = window.innerHeight;
		const minHeight = 500; // height threshold before collapse all div

		if(winHeight < minHeight){
			this.setState({
				toggleSavedAttachments: false,
				toggleActionElements: false,
				visibleChangeRecepient: false
			})
		} else {
			this.setState({
				toggleSavedAttachments: true,
				toggleActionElements: true,
				visibleChangeRecepient: true
			})
		}
	},
	getScrollbarLocation: function() {
		return (document.documentElement && document.documentElement.scrollTop) ||  document.body.scrollTop;
	},
	getDocHeight: function() {
		return Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);
	},
	hasReachedBottom: function() {
		return this.getScrollbarLocation() + window.innerHeight == this.getDocHeight();
	},
	handleScrollAndSetSticky: function(){
		var answerBox = document.getElementById('answerBox');
		if (answerBox) {
			var panelBodyLocation = parseInt($('.panel-body').offset().top, 10);
			if(ErrandHelper.isIEBasedBrowser && window.location.hash.indexOf('answer') == 1){ // only executed in IE since position sticky is supported in Chrome and Firefox, also only executed on errand answer page
				if(!this.props.wfSettings.signaturePreviewContainer && window.innerHeight > 700){
					if (!ErrandHelper.isMobile()) {
						if (this.getScrollbarLocation() >= panelBodyLocation) {
							this.setState({isSticky: true});
						} else {
							this.setState({isSticky: false});
						}
						if(this.hasReachedBottom()){
							answerBox.style.top = "-80px";
						}else{
							answerBox.style.top = 0;
						}
					} else {
						answerBox.className = "";
						answerBox.classList.add("relative");
					}
				}
			}
		}
	},
	csv2Array: function(csv) {
		var list = [];
		csv.split(",").forEach(function(v,i) {
			if(v && v != "") {
				list.push({id:v, value: v});
			}
		});
		return list;
	},
	updateSavedAttachment: function(props) {
		if(props.errandInfo && props.errandInfo.extendedData &&
			props.errandInfo.extendedData.data) {
			var ext = props.errandInfo.extendedData.data;
			var saved = ext.answer_mail_attachments;
			if(saved && saved.length > 0) {
				var attach = [];
				$.each(saved, function(i, v) {
					attach.push(v.id);
				});
				this.setState({uploadedAttachments: attach});
			}
		}
	},
	updateService: function(props) {
		if(props.currentErrandData && props.currentErrandData.data) {
			this.setState({currentErrandService:
				props.currentErrandData.data.service});
		}
	},
	updateReplyToAddr: function(props) {
		if(props.currentErrandData && props.currentErrandData.data) {
			var curr = props.currentErrandData.data;
			if(curr.fromAddress) {
				this.setState({selectedReplyAddress: [{
					id: curr.fromAddress, value: curr.fromAddress}]});
			} else {
				this.setState({selectedReplyAddress: []});
			}
		}
	},
	updateAddresses: function(props) {
		if(!props.errandInfo || !props.errandInfo.extendedData ||
			!props.errandInfo.extendedData.data) {
			this.updateReplyToAddr(props);
		}
		if(!props.currentErrandData || !props.currentErrandData.data) {
			return;
		}
		var ext = props.errandInfo.extendedData.data;
		var curr = props.currentErrandData.data;
		if(ext) {
			if(ext.answer_bcc != "") {
				this.setState({selectedReplyAddressBCC:
					this.csv2Array(ext.answer_bcc)});
			}
			if(ext.answer_cc != "") {
				this.setState({selectedReplyAddressCC:
					this.csv2Array(ext.answer_cc)});
			}
			if(ext.answer_to != "") {
				if(curr.wasForwardedToExternal) {
					this.setState({selectedReplyAddressForwardExternal:
						this.csv2Array(ext.answer_to)});
				} else {
					if(!curr.wasClonedAndForwardedExternal) {
						this.setState({selectedReplyAddress:
							this.csv2Array(ext.answer_to)});
						return;
					}
				}
			}
		}
		this.updateReplyToAddr(props);
	},
	fetchWFSettingsFroCache: function (){
		return this.props.wfSettings;
	},
	setErrandHotkeys: function(controls){
		var keysAction = [];
		if(this.props.currentErrandId != 0){
			keysAction.push({key : "A", name: "Save Answer", type : "handler", action : this.handleSaveAnswer});
		}
		keysAction.push(
			{key : "C", name: "Clear Answer", type : "handler", action : this.clearAnswer},
			{key : "D", name: "Delete Errands", type : "handler", action : this.handleDelete},
			{key : "F", name: "Attach File", type : "handler", action : this.viewAttachmentAdder},
			{key : "I", name: "Return To Inbox", type : "handler", action : this.handleReturnToInbox.bind(null, this.props.errandSrcContext)},
			{key : "J", name: "Return To Search", type : "handler", action : this.handleReturnToSearch.bind(null, this.props.errandSrcContext)},
			{key : "K", name: "Spellcheck", type : "handler", action : this.handleSpellCheck},
			{key : "S", name: "Send Answer", type : "handler", action : this.handleSendAnswer},
			{key : "O", name: "Close Errand", type : "handler", action: this.handleClose}
		);
		return keysAction;
	},
	handleSpellCheck: function(){
		CKEDITOR.instances["AnswerWysiwyg"].execCommand('spellchecker');
	},
	handleShortCutKeys: function(handler){
		return handler;
	},
	fetchExternalQueries: function(eid,clientX,clientY){
		var activeModal = this.state.targetModal;
		var content = [];
		var queryExist = [];
		$.get( webserverRoot + "externalExpertQueries", {errand: parseInt(eid)})
		.done(function(data){
			var modalBtnText = "";
			data.mcam.channels.forEach(function( d ){
				if(typeof d.type != undefined && d.type == "Result" ){
					content.push(d.content);
					if(d.content.indexOf("Sent") > -1){
						if(this.state.eeLightsOn == "activated"){
							modalBtnText = "Turn Light Off";
						}else{
							modalBtnText = "Turn Light On";
						}
						queryExist.push('1');
					}else{
						queryExist.push('0');
					}
				}
			}.bind(this));
			var modalContent = content[0].replace("\n", "<br>");
			var queryExistence = queryExist[0];
			ErrandHelper.manageExternalExpert({x:clientX,y:clientY,content:modalContent,btnText:modalBtnText,queryExist: queryExistence,onClick:this.handleTurnExternalExpertLightsOff,onClickPopup:this.handleOpenExternalExpertPagePopup});
		}.bind(this));
	},
	returnForwardToAreas: function(forwardAreas){
		var forwardToAreaList = [];
		forwardAreas.forEach(function(d){
			if(d.Areas != undefined){
				d.Areas.forEach(function( a ){
					forwardToAreaList.push({
						id: a.Id,
						value: a.Name
					});
				}.bind(this));
			}
		}.bind(this));
		return forwardToAreaList;
	},
	fetchAreaData: function(areaId) {
		return $.post(webserverRoot + 'errand/areaData', {areas: areaId});
	},
	getCurrentArea: function( areaId ){
		return this.props.areaData[areaId];
	},
	getCurrentErrandArea: function(){
		if(this.state.areasForErrand!=""){
			return(
				this.state.areasForErrand
				)
		}else{
			return (
				this.props.currentErrandData && this.props.currentErrandData.data ? this.props.currentErrandData.data.area : 0
			);
		}
	},
	getExternalExpertStatus : function(){
		var eeLightStatus = this.props.errandInfo.acquireErrand.data.externalexpartlight;
		var eeStatus = this.props.errandInfo.acquireErrand.data.errand_externalexperts;
		if(eeStatus == 1){
			this.state.eeLightsOn = "active";
		}
		if(eeStatus == 1 && eeLightStatus == 0 ){
			this.state.eeLightsOn = "active";
		}
		if(eeLightStatus == 1 || eeStatus == 2 ){
			this.state.eeLightsOn = "activated";
		}
		if(eeStatus == 0){
			this.state.eeLightsOn = "deactivate";
		}
		var externalExpertStatus = this.state.eeLightsOn;
		return externalExpertStatus;
	},
	handleInsertAnswer: function(item){
		this.state.answerBody = item.body;
		this.setState(this.state);
	},
	handleAppendAnswer: function(item){
		this.state.answerBody += item.body;
		this.setState(this.state);
	},
	renderAutocompleteItemActions: function(item,fields){
		return (
			<SearchAutoCompleteAction item={item} fields={fields} onInsertAnswer={this.handleInsertAnswer} onAppendAnswer={this.handleAppendAnswer}/>
		);
	},
	returnErrandServiceTitle: function( service ){
		switch( service ){
			case Workflow.Errand.SERVICE_MANUAL:
			case Workflow.Errand.SERVICE_EMAIL:
				return I("Change recipients");
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				return I("Facebook actions");
			case Workflow.Errand.SERVICE_TWITTER:
			case Workflow.Errand.SERVICE_MANUAL_TWITTER:
				return I("Twitter actions");
			case Workflow.Errand.SERVICE_VKONTAKTE:
			case Workflow.Errand.SERVICE_MANUAL_VKONTAKTE:
				return I("VKontakte actions");
			case Workflow.Errand.SERVICE_INSTAGRAM:
				return I("Instagram actions");
			default:
				return I("Change recipients");
		}
	},
	renderErrandThreads: function( errand ){
		if((this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ) ||
			(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.props.externalManual == true)){
			if(errand.data) {
				if(this.state.questionBody) {
					errand.data.body = this.state.questionBody;
				}else {
					errand.data.body = this.props.errandInfo.acquireErrand.data.question_body;
				}
			}
			return(
				<div id="ErrandThreadContainer">
					<ErrandThread
						errand={errand}
						onSelectErrand={this.handleErrandThreads}
						visible={true}
						groupErrandsByThread={this.props.wfSettings.groupErrandsByThread}
						onClickErrandToLoad={this.handleClickToLoadErrand}
						clientContactDetails={this.props.clientContactDetails}
						relatedErrands={this.props.errandInfo.acquireErrand.data.related_errands}
						openedByExternal={this.props.openedByExternal}
						isClearIT={this.props.isClearIT}
						onAcquired={this.handleErrandAcquire}
						contextName = {this.props.currentContextName}
						changeKey = {this.changeKey}
					/>
				</div>)
		}
	},
	handleClickToLoadErrand: function( errand, assocList, context ){
		this.handleErrandThreads( assocList );
		this.props.onHandleErrandActon( errand, context );
	},
	handleErrandThreads: function( selectedItems ){
		this.state.associated_list.length = 0;
		var uniqueList = selectedItems.filter(function(sitem, pos){
			return selectedItems.indexOf(sitem) == pos;
		});
		this.setState({associated_list: uniqueList});
	},
	handleErrandAcquire: function(id){
		console.log('handleErrandAcquire',id);
	},
	fetchDetect: function( tc ){
		var translatioServer = window.location.protocol + "//" +window.location.hostname + "/Cention/";
		var translateUrl = translatioServer + "translate/detect";
		var ts = {
					text: tc
				};
		return $.ajax(translateUrl, {
				type: "POST",
				data: JSON.stringify(ts),
				contentType: "application/json",
			});
	},
	showHideTranslation: function(){
		var vtb = !this.state.visibleTranslationBox;
		var strTranslationContent = (this.props.currentErrandData.data.subject + this.props.currentErrandData.data.body).substring(1, 300);
		/*** fromLang getting value from babeld instead if wfSettings ***/
		this.fetchDetect(strTranslationContent)
		.then(function(result){
			let rs = JSON.parse(result);
			if (rs.error) {
				alert(rs.error);
				return;
			}
			this.setState({translateFromLang: rs.text, visibleTranslationBox: vtb, visibleTranslationBoxClass: "active"});
		}.bind(this))
		.fail(function(result){
			this.setState({visibleTranslationBox: false, visibleTranslationBoxClass: ""});
			alert(I("We're sorry, the translation service is not responding."));
		}.bind(this));
	},
	renderTranslationBox: function(){
		if(this.state.visibleTranslationBox){
			return(
				<ErrandTranslation
					shouldVisible = {this.state.visibleTranslationBox}
					id = "ErrandTranslation_"
					translationResult = "TranslationResultBox"
					contextType = "errand"
					fromLang = {this.state.translateFromLang}
					onSelectToLang = {function(opt){
							this.setState({translateToLang: opt, showTranslationRes: true});
						}.bind(this)
					}
				/>
			);
		}
	},
	showTranslationResult: function(){
		if(this.state.showTranslationRes){
			var translationContent = [];
			translationContent.push(this.props.currentErrandData.data.subject);
			if( this.props.currentErrandData.data && this.props.currentErrandData.data.body )
				translationContent.push(this.props.currentErrandData.data.body);
			return(
				<TranslationResultBox
					targetContents={translationContent}
					fromLang={this.state.translateFromLang}
					toLang={this.state.translateToLang}
				 />
			);
		}
	},
	handleEditQuestion: function(){
		this.state.questionEditable = !this.state.questionEditable;
		if(this.state.questionEditable)
			this.state.editQuestionClass="active";
		else
			this.state.editQuestionClass="";
		document.getElementById('editableBody').setAttribute('contenteditable', this.state.questionEditable);
		document.getElementById('editableBody').setAttribute('class', this.state.questionEditable);
		document.getElementById('ResetLink').style.display = (this.state.questionEditable ? 'inline-block' : 'none');
	},
	handleQuestionReset: function(question){
		document.getElementById('editableBody').innerHTML = question;
	},
	handleOnImportant: function( priority ){
		this.state.priority = priority;
	},
	appendAlternativeAnswerAttachments: function(attachments) {
		if (Array.isArray(attachments) == false) {
			return;
		}
		var items = [];
		for (var i = 0; i < attachments.length; i++) {
			this.state.libraryAttachments.push(attachments[i].id);
			items.push({
				id: attachments[i].id,
				name: attachments[i].name,
				value: attachments[i].name,
				estSize: attachments[i].size,
				download: attachments[i].url,
				src: attachments[i].url
			});
		}
		ReactDOM.render(
			React.createElement(AgentAttachment, {
				items: items,
				onHandle: function(attachment) {
					if (this.state.libraryAttachments.indexOf(attachment.id) !== -1) {
						this.state.libraryAttachments.splice(this.state.libraryAttachments.indexOf(attachment.id), 1);
					}
				}.bind(this)
			}),
			document.getElementById('ErrandAttachment')
		);
	},
	handleAlternativeAnswerInsert: function(data) {
		this._Cke('Answer').setData(data.answer);
		this._Cke('Answer').updateElement();
		this.appendAlternativeAnswerAttachments(data.attachments);
	},
	handleAlternativeAnswerAppend: function(data) {
		this._Cke('Answer').setData(this._Cke('Answer').getData() + '<br>' + data.answer);
		this._Cke('Answer').updateElement();
		this.appendAlternativeAnswerAttachments(data.attachments);
	},
	renderAnswerBtn: function(action, extraClass, onAction, reference){
		if( !this.props.errandInfo.acquireErrand.acquired)
			if( !this.props.wfSettings.onlyShowErrandIfAcquired && this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_NEW)
				return (<div className="btn-group"></div>)
		var actionLabel = "";
		var shortcutLabel = "";
		var btnId = "btn"+action.replace(/\s+/g, '');
		var dis = false;
		if(this.state.activateForwardExternal &&
			!(reference == 1 || reference == 3 || reference == 9 /* resend */ || reference == 11)) {
			dis = true;
		}
		if( this.state.clickSendCaptured ){
			dis = true;
			if( reference == 1){
				action = "<i id=\"searchIcon\" style=\"margin-right:7px\" class=\"fa fa-circle-o-notch fa-spin\"></i>" + I("Sending...");
			}
		}
		if(this.state.hotkeys){
			var labelText = action;
			switch(reference){
				case 1: //send
					shortcutLabel = "<span class='hotkey-label'>S</span>";
					if(labelText.indexOf("S") > -1){
						actionLabel = labelText.replace('S', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 2: //save
					shortcutLabel = "<span class='hotkey-label'>a</span>";
					if(labelText.indexOf("a") > -1){
						actionLabel = labelText.replace('a', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 3: //clear
					shortcutLabel = "<span class='hotkey-label'>C</span>";
					if(labelText.indexOf("C") > -1){
						actionLabel = labelText.replace('C', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 4: //send to all
					shortcutLabel = "<span class='hotkey-label'>d</span>";
					if(labelText.indexOf("d") > -1){
						actionLabel = labelText.replace('d', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 5: //publish
					shortcutLabel = "<span class='hotkey-label'>P</span>";
					if(labelText.indexOf("P") > -1){
						actionLabel = labelText.replace('P', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 6: //unpublish
					shortcutLabel = "<span class='hotkey-label'>U</span>";
					if(labelText.indexOf("U") > -1){
						actionLabel = labelText.replace('U', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 7: //recycle
					shortcutLabel = "<span class='hotkey-label'>R</span>";
					if(labelText.indexOf("R") > -1){
						actionLabel = labelText.replace('R', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 8: //reopen
					shortcutLabel = "<span class='hotkey-label'>O</span>";
					if(labelText.indexOf("O") > -1){
						actionLabel = labelText.replace('O', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 9: //resend
					shortcutLabel = "<span class='hotkey-label'>E</span>";
					if(labelText.indexOf("E") > -1){
						actionLabel = labelText.replace('E', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 10: //open library
					shortcutLabel = "<span class='hotkey-label'>O</span>";
					if(labelText.indexOf("O") > -1){
						actionLabel = labelText.replace('O', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				case 11: //suggest for library
					shortcutLabel = "<span class='hotkey-label'>O</span>";
					if(labelText.indexOf("O") > -1){
						actionLabel = labelText.replace('O', shortcutLabel);
					}else{
						actionLabel = labelText+" ["+shortcutLabel+"]";
					}
					break;
				}
			return (
				<div className="btn-group">
					<button id={btnId} type="button" onClick={onAction}
						className={"btn btn-primary dropdown-toggle action-buttons "+extraClass}
						data-toggle="dropdown" aria-haspopup="false"
						aria-expanded="false" disabled={dis}
						dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(actionLabel)}}>
					</button>
				</div>
			)
		}else{
			return(<div className="btn-group">
					<button id={btnId} type="button" onClick={onAction}
						className="btn btn-primary dropdown-toggle action-buttons"
						data-toggle="dropdown" aria-haspopup="false"
						aria-expanded="false" disabled={dis}
						style={{backgroundColor: extraClass}}
						dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(action)}}>
					</button>
				</div>
			)
		}
	},
	renderAllButtons: function(errand, acquireErrand, bttns) {
		var saveButton = "";
		var clearButton = "";
		var sendButton = "";
		var sendAllButton = "";
		var reopenButton = "";
		var resendButton = "";
		var publishButton = "";
		var unpublishButton = "";
		var suggestLibraryButton = "";
		var showSendToAll = true;
		if( this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH || this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH_ANSWER ){
			if( acquireErrand.data.deleted ){
				reopenButton = this.renderAnswerBtn(I("Reopen"),"grey",this.handleReopenErrand,8);
			}
			else if( errand.data.closed && !acquireErrand.data.deleted){
				if( F("editClosedErrands") )
					saveButton = this.renderAnswerBtn(I("Save"),"grey", this.handleSaveAnswer,2);
				if( errand.data.service != Workflow.Errand.SERVICE_VOICE){
					resendButton = this.renderAnswerBtn(I("Resend"),"",this.handleResendErrand,9);
				}
				reopenButton = this.renderAnswerBtn(I("Reopen"),"grey",this.handleReopenErrand,8);
				if(errand.data.service == Workflow.Errand.SERVICE_VOICE &&
					this.state.activateForwardExternal == true){
					resendButton = this.renderAnswerBtn(I("Resend"),"",this.handleResendErrand,9);
				}
				/*
				Anna doesnt want that now. but I kept for next time if she want again.
				{suggestLibraryButton}
				if( this.props.wfSettings.centionLibrary ){
					suggestLibraryButton = this.renderAnswerBtn(I("Suggest for library"),"",this.handleSuggestLibrary,11);
				}*/
			}else{
				saveButton = this.renderAnswerBtn(I("Save"),"grey", this.handleSaveAnswer,2);
				clearButton = this.renderAnswerBtn(I("Clear"),"grey",this.clearAnswer,3);
				sendButton = this.renderAnswerBtn(I("Send"),"",this.handleSendAnswer,1);
				if(bttns.sendAll) {
					sendAllButton = this.renderAnswerBtn(I("Send to all"),"", this.handleSendAnswerToAll,4);
				}
			}
		}
		if( this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_NEW || this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MY || this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ){
			saveButton = this.renderAnswerBtn(I("Save"),"grey", this.handleSaveAnswer,2);
			clearButton = this.renderAnswerBtn(I("Clear"),"grey",this.clearAnswer,3);
			if ((typeof this.props.externalManual == "undefined") ||
				(this.props.externalManual == false)){
				sendButton = this.renderAnswerBtn(I("Send"),"",this.handleSendAnswer,1);
				if(bttns.sendAll) {
					sendAllButton = this.renderAnswerBtn(I("Send to all"),"", this.handleSendAnswerToAll,4);
				}
			}
		}
		if((this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) &&
			(this.props.externalManual == false)){
			saveButton = this.renderAnswerBtn(I("Save"),"grey", this.handleSaveAnswer,2);
		}
		if(this.props.currentContextName ==
			ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.props.externalManual == true &&
			errand.data.service == Workflow.Errand.SERVICE_VOICE){
				reopenButton = this.renderAnswerBtn(I("Reopen"),"grey",
					this.handleReopenErrand,8);
		}
		if(typeof F('disable-send-to-all') !== 'undefined' && F('disable-send-to-all')){
			showSendToAll = false;
		}
		return(<span>
				{saveButton}
				{clearButton}
				{sendButton}
				{showSendToAll ? sendAllButton : ""}
				{publishButton}
				{unpublishButton}
				{reopenButton}
				{resendButton}
		</span>);
	},
	getExternalSystem: function(areaId, currentAreaData){
		/*var currentArea = this.props.areaData[areaId];*/
		var currentArea = currentAreaData;
		var hasExt = false;
		var urls = [];
		if( currentArea != undefined ){
			if( typeof currentArea.external_system_url_array != undefined && currentArea.external_system_url_array != null && currentArea.external_system_url_array.length > 0 ){
				hasExt = true;
				urls = currentArea.external_system_url_array;
			}
		}
		return {
			hasExSys: hasExt,
			systemUrl: urls
		}
	},
	manageAutoOpenExternal: function( extSys ){
		setTimeout(function(){
			var email = this.props.currentErrandData.data.fromAddress;
			var externalSystemUrls = extSys.externalSystemUrls;
			if(externalSystemUrls.length > 0 &&( extSys.howOpenExSysByAgent == 2 || extSys.howOpenExSysByAgent == 3 )){
				for(var i = 0; i < externalSystemUrls.length; i++){
					var url = externalSystemUrls[i].replace('[EMAIL]', email);
					if( url.length > 0 ){
						var external = (extSys.howOpenExSysByAgent == 2
										? window.open(url, '_blank' + i)
										: window.open(url, 'external-system' + i, 'scrollbars=yes,menubar=yes,toolbar=yes,width=1024,height=768'));
						if(external) { // some time browser block the popup which will cause null value on external
							external.focus();
						} else {
							console.log("can not create window - browser blocked popup?");
						}
					}
				};
			}
		}.bind(this), 1000);
	},
	generateSelectedTags: function(allTags){
		var errandTags = this.props.errandInfo.extendedData.data.errand_tags;
		var tagsShouldSelected = [];
		if (!Array.isArray(errandTags) || !Array.isArray(allTags)) {
			return tagsShouldSelected;
		}
		errandTags.forEach(function(tagIds) {
			var tags = [];
			var found = tagIds.every(function(tagId) {
				for (var i = 0; i < allTags.length; i++) {
					var areaTag = allTags[i];
					if(areaTag != null) {
						if (tagId == areaTag.tagId) {
							tags.push(areaTag);
							return true;
						}
						if (Array.isArray(areaTag.children)) {
							for (var j = 0; j < areaTag.children.length; j++) {
								var level2Tag = areaTag.children[j];
								if (tagId == level2Tag.tagId) {
									tags.push(level2Tag);
									return true;
								}
								if (Array.isArray(level2Tag.children)) {
									for (var k = 0; k < level2Tag.children.length; k++) {
										var level3Tag = level2Tag.children[k];
										if (tagId == level3Tag.tagId) {
											tags.push(level3Tag);
											return true;
										}
									}
								}
							}
						}
					}
				}
				return false;
			});
			if (found) {
				tagsShouldSelected.push(tags);
			}
		});
		if(this.props.openedByExternal == true){
			this.extQueueErrandTagDone = true;
		}
		this.setState({selectedTags: tagsShouldSelected, changeArea: true});
	},
	manageSavedAttachment: function( attachment, todo ){
		if(todo === 'add')
			this.state.uploadedAttachments.push(attachment.id);
		if(todo === 'del'){
			if(this.state.uploadedAttachments.indexOf(attachment.id) !== -1)
				this.state.uploadedAttachments.splice(this.state.uploadedAttachments.indexOf(attachment.id), 1);
		}
	},
	changeEELightFromIFrame: function(status){
		if(status){
			this.refs.errActionTools.handleEESwitch('activated');
		}else{
			this.refs.errActionTools.handleEESwitch('active');
		}
	},
	renderContactCard: function(serviceName, fromAddress, service){
		return (
			<ContactCardBox
				errandSrc={this.props.currentContextName}
				handleContactCard = {this.handleContactCard}
				serviceName={serviceName}
				fromAddress={fromAddress}
				service={service}
				channels = {this.props.channelList}
				externalManual={this.props.externalManual}
				custNotesCount={this.state.custNotesCount}
				clientContactCount={this.state.clientContactCount}
			/>
		);
	},
	renderIncludeQuestionCheckbox: function(cbStyle) {
		var checked = this.props.qhistoryCheckboxes.question;
		var dis = false;
		if(this.state.activateForwardExternal &&
			this.props.qhistoryCheckboxes.all) {
			dis = true;
			checked = true;
		}
		return (
			<div className='errandHistoryCheck col-md-1' style={cbStyle}>
				<ErrandThreadCheckbox name="threadname"
					id={"question"} disabled={dis} checked={checked}
					value="includeQuestionToggle"
					title={I("Include Question")}
					onChckBoxChange={this.props.onChckBoxChange} />
			</div>
		);
	},
	renderDoneDateFeature: function(doneDate){
		var doneDateOnly = ErrandHelper.DATE_NOT_SET;
		if(doneDate != undefined && doneDate != "No Date"){
			doneDateOnly = doneDate.substring(0, 10);
		}
		this.state.errandDueDate = doneDateOnly;
		return (
			<Feature tag="doneDateFeature">
				<p className="fourthColumn" id="dueDateWrapper"><span className="dataTitle">{I("DUE DATE")} : </span><span><Calendar selectedDate={doneDate == "No Date" ? "None" : doneDate} type="link" id="dueDate" allowPastDate={false} onChange={this.handleDueDateChange} onDelete={this.onDeleteDueDate}/></span></p>
			</Feature>
		)
	},
	setDefaultPersonalisation: function() {
		var defaultSalutation = 0;
		var defaultSignature = 0;
		var extendedData = this.props.errandInfo.extendedData;
		defaultSalutation = this.prefSalutation(this.state.currentAreaData);
		defaultSignature = this.prefSignature(this.state.currentAreaData);
		var extData = extendedData.data;
		if(typeof extData != 'undefined' ){
			if( extData.errandAnswer ){
				if(extData.answer_salutation > 0)
					defaultSalutation = extData.answer_salutation.toString();
				if(extData.answer_signature > 0)
					defaultSignature  = extData.answer_signature.toString();
			}
		}
		if(this.state.changeArea) {
			defaultSalutation = this.prefSalutation(this.state.currentAreaData);
			defaultSignature = this.prefSignature(this.state.currentAreaData);
		}
		this.setState({
			selectedSalutations: defaultSalutation,
			selectedSignature: defaultSignature
		});
	},
	salutationBodyContents: "",
	signatureBodyContents: "",
	renderAttachmentContainer: function(mailAttachments){
		return mailAttachments !== "" ? <div id="SavedAttachement" className={this.toggleSavedAttachments ? 'none' : 'block'}>{I("Saved attachments:")}{mailAttachments}</div> : ""
	},
	renderAttachmentOption: function(wfSettings, extendedData){
		return(
			<div className="col-md-12 nomargin nopadding">
				<div className="attachmentOptions">
					{this.addErrandAttachmentToAnswer(wfSettings, extendedData)}
					<div className="col-md-7">
						<div id="ExternalExpertAttachment" ref="exAttachment"></div>
					</div>
					<div className="col-md-5">
						<div id={"ArchiveAndBrowseBox"}></div>
					</div>
					<div className="clear"></div>
				</div>
			</div>
		)
	},
	renderPreviewSalutation: function() {
		var salutationContents = "";
		var usSalCont = this.concatUASalCont(this.state.currentAreaData);
		if(usSalCont.length > 0 ){
			usSalCont.forEach(function(salContents, i){
				for(var prop in salContents) {
					if(prop == this.state.selectedSalutations)
						salutationContents = salContents[prop];
				}
			}.bind(this));
		}
		this.salutationBodyContents = salutationContents;
		if( this.props.wfSettings.salutationPreviewContainer ){
			if(this.state.selectedSalutations > 0){
				return (
				<div id="previewSalutation">
					<PreviewSalutation
						salutationContent={salutationContents}
					/>
				</div>
				)
			}else{
				return (
					<div id="previewSalutation"></div>
				)
			}
		}
	},
	renderPreviewSignature: function() {
		var signatureContents = "";
		var usSignCont = this.concatUASignCont(this.state.currentAreaData);
		if( usSignCont.length > 0 ){
			usSignCont.forEach(function(sigContents, i){
				for(var prop in sigContents) {
					if(prop == this.state.selectedSignature)
						signatureContents = sigContents[prop];
				}
			}.bind(this));
		}
		this.signatureBodyContents = signatureContents;
		if(this.props.wfSettings.signaturePreviewContainer) {
			if(this.state.selectedSignature > 0){
				return (
					<div id="previewSignature">
						<PreviewSalutation
							salutationContent={signatureContents}
						/>
					</div>
				)
			} else {
				return (
					<div id="previewSignature"></div>
				)
			}
		}
	},
	getUniqueData: function(data){
		var usedObjects = {};
		if(data == null)
			return data;
		for (var i=data.length - 1;i>=0;i--) {
			var so = JSON.stringify(data[i]);
			if (usedObjects[so]) {
				data.splice(i, 1);
			} else {
				usedObjects[so] = true;
			}
		}
		return data;
	},
	concatUserNAreaSal: function( currentAreaData ){
		var salUserArea = [];
		if(currentAreaData.salutations != null && currentAreaData.salutations.length > 0 ){
			salUserArea = currentAreaData.salutations;
		}
		if( currentAreaData.user_salutations != null && currentAreaData.user_salutations.length > 0 ){
			if( !currentAreaData.area_admin_tick_sal ){
				if( salUserArea.length > 0)
					salUserArea = salUserArea.concat(currentAreaData.user_salutations);
				else
					salUserArea = currentAreaData.user_salutations;
			}
		}
		return salUserArea;
	},
	concatUserNAreaSign: function( currentAreaData ){
		var signUserArea = [];
		if(currentAreaData.signatures != null && currentAreaData.signatures.length > 0 ){
			signUserArea = currentAreaData.signatures;
		}
		if( currentAreaData.user_signatures != null && currentAreaData.user_signatures.length > 0){
			if( !currentAreaData.area_admin_tick_sign ){
				if( signUserArea.length > 0 )
					signUserArea = signUserArea.concat(currentAreaData.user_signatures);
				else
					signUserArea = currentAreaData.user_signatures;
			}
		}
		return signUserArea;
	},
	prefSalutation: function( currentAreaData ){
		var prefSal = "0";
		if( currentAreaData.area_salutation_pref != null )
			if(currentAreaData.area_salutation_pref != 0)
				prefSal = currentAreaData.area_salutation_pref.toString();
		//User prefered salutation should be first if area_admin_tick_sal means admin ticked not to change
		if( currentAreaData.area_admin_tick_sal != null && !currentAreaData.area_admin_tick_sal){
			if( currentAreaData.user_salutation_pref != null)
					if( currentAreaData.user_salutation_pref != 0)
						prefSal = currentAreaData.user_salutation_pref.toString();
		}
		return prefSal;
	},
	prefSignature: function( currentAreaData ){
		var prefSign = "0";
		if( currentAreaData.area_signature_pref != null )
			if(currentAreaData.area_signature_pref != 0)
				prefSign = currentAreaData.area_signature_pref.toString();
		//User prefered signature should be first if area_admin_tick_sign means admin ticked not to change
		if( currentAreaData.area_admin_tick_sign != null && !currentAreaData.area_admin_tick_sign){
			if( currentAreaData.user_signature_pref != null)
				if( currentAreaData.user_signature_pref != 0)
					prefSign = currentAreaData.user_signature_pref.toString();
		}
		return prefSign;
	},
	getAreaData: function() {
		var area;
		if(this.state.changeArea) {
			area = this.state.currentAreaData;
		} else {
			area = this.props.areaData;
			this.state.currentAreaData = area;
			this.state.currentAreaDatas = this.props.areaData;
		}
		return area;
	},
	renderHotKeyComponent: function(){
		var hotkeys = F('hotkeys');
		if(hotkeys){
			return(
				<CentionHotKeys
					id="ErrandHotkeyDialog"
					ref="errandHotKeyDialog"
					keys={this.setErrandHotkeys()}
					activated={hotkeys}
					onEnter={this.handleShortCutKeys}
					hotCtrlKeys={this.state.hotCtrlKeys}
				/>
			)
		}
	},
	checkAndLockTranscation: function(name){
		if (features.solidusEnabled){
			if(this.state.lockTransaction == true){
				console.log("external queue transaction locked. not doing " +
					name);
				return false;
			} else {
				this.state.lockTransaction = true
				this.setState(this.state);
			}
		}
		return true;
	},
	unlockTransaction: function(){
		if (features.solidusEnabled){
			this.state.lockTransaction = false
			this.setState(this.state);
		}
	},
	handleQueueToMe: function(e){
		var errands = [];
		errands.push(parseInt( this.props.currentErrandData.id, 10) );
		if(this.checkAndLockTranscation("queue to me") == false) return
		ErrandHelper.queueToMe(errands,function(){
			this.unlockTransaction();
			if((typeof this.props.openedByExternal !== "undefined") &&
				(this.props.openedByExternal == true)){
				open(location, '_self').close();
			}
		}.bind(this));
	},
	handleHistorySort: function(sortBy){
		this.setState({historySortBy: sortBy});
	},
	toggleSavedAttachments: function(){
		this.setState({toggleSavedAttachments: !this.state.toggleSavedAttachments});
	},
	toggleActionElements: function(){
		this.setState({toggleActionElements: !this.state.toggleActionElements});
	},
	renderHistorySortOption: function() {
		if(this.props.historyData.length > 2) {
			return (
			<span style={{'paddingLeft': '5px','fontSize':'10px'}}>
				<DropDown
					id="historySort"
					name="Sort"
					selectedItems="1"
					items={[{id: 1, value: "Newest to Oldest"}, {id: 2, value: "Oldest to Newest"}]}
					fields={{id: "id", name: "value"}}
					onChange={this.handleHistorySort}
				/>
			</span>
			);
		}else {
			return "";
		}
	},
	handleCheckingMembership: function(email, url) {
		var req = new XMLHttpRequest();
		req.open('GET', url+'?email='+email, false);
		req.send(null);
		if(req.status == 200) {
			if(req.responseText == email+" is a GSC Member") {
				return true;
			}else {
				return false;
			}
		}else {
			return false;
		}
	},
	renderMembershipStatus: function() {
		var member = false;
		var active = "";
		var iconClass = "fa fa-user-times";
		var title = I(" Not a member");
		if( F('check-membership-status') && F('check-membership-status-url') ) {
			member = this.state.membershipStatus;
			if(member) {
				active = "rgb(0, 136, 204)";
				title = I(" Is a member");
				iconClass = "fa fa-user";
			}
			return(
				<NicePopover txt={title} keyName="membershipStatus" type="icon" iconClass={iconClass} customStyle={{color: active, fontSize: "13px"}} />
			)
		}else {
			return "";
		}
	},
	externalExpertAlerts: function(extendedData){
		if(typeof extendedData.data != 'undefined' && extendedData.data.ee_close_thread_message != undefined && extendedData.data.ee_close_thread_message != "" ){
			$('#ErrandFromEEAnswerReply').html(extendedData.data.ee_close_thread_message);
			$('#ErrandFromEEAnswerReply').attr('title', I('External expert answer in closed thread'));
			$('#ErrandFromEEAnswerReply').dialog({
					width: 500,
					modal: true
			});
		}
	},
	render: function() {
		if(this.props.wfSettings !== undefined && this.props.currentErrandData != null && Object.keys(this.props.areaData).length !== 0){
			var that = this;
			var errand = this.props.currentErrandData;
			var openExternalSystemSettings = {};
			var acquireErrand = this.props.errandInfo.acquireErrand;
			var extendedData = this.props.errandInfo.extendedData;
			var areaId = this.getCurrentErrandArea();
			var firstAreaItem = this.getCurrentErrandArea().toString();
			if( firstAreaItem == "" || firstAreaItem == 0)
				firstAreaItem = errand.data.area.toString();
			if( this.state.answerBody == "")
				this.state.answerBody = acquireErrand.data.answer_body;
			if( this.state.questionBody == "")
				this.state.questionBody = acquireErrand.data.question_body;
			this.state.priority = extendedData.data.errand_priority;
			var currentAreaData = this.getAreaData();
			openExternalSystemSettings.hasExternalSystem = this.getExternalSystem( areaId, currentAreaData ).hasExSys;
			openExternalSystemSettings.externalSystemUrls = this.getExternalSystem( areaId, currentAreaData ).systemUrl;
			openExternalSystemSettings.howOpenExSysByAgent = this.props.wfSettings.howToOpenExternalSystemForAgent;
			var newtaglist = (currentAreaData && currentAreaData.normal_tags != null ? currentAreaData.normal_tags : []);
			this.getExternalExpertStatus();
			this.manageAutoOpenExternal( openExternalSystemSettings );
			var editQuestion = "";
			var answerReplyAlternativeAnswer = "";
			var answerReplyTemplates = "";
			var showContactCard = "";
			var mailAttachments = "";
			if(extendedData.data) {
				var answerMailAttach = extendedData.data.answer_mail_attachments;
				if(answerMailAttach && answerMailAttach.length > 0) {
					mailAttachments = <AgentAttachment origin="SavedAttachement"
						items={extendedData.data.answer_mail_attachments}
						onChangeArea={true}
						onHandle={function(attachment) {
							this.manageSavedAttachment(attachment, 'del');
							$.post(webserverRoot + "errand/removeTempAttachment", {fid: attachment.id});
						}.bind(this)}
					/>;
				}
			}
			var answerOptionDivider = <span className="divider"> | </span>;
			var answerOptionLeft = "col-md-6 nomargin nopadding iconsLeft";
			var answerOptionRight = "col-md-6 nomargin nopadding textRight";
			if( errand.data.closed || acquireErrand.data.deleted ){
				answerOptionLeft = "col-md-1 nomargin nopadding iconsLeft";
				answerOptionRight = "col-md-11 nomargin nopadding textRight";
				answerOptionDivider = "";
			}

			if( F('edit-question') )
				editQuestion = <span><i className={"fa fa-pencil er-edit " + this.state.editQuestionClass} title={I("Edit")} data-toggle="tooltip" onClick={this.handleEditQuestion}></i>
									<i id="ResetLink" className="fa fa-retweet er-reset" title={I("Reset")} data-toggle="tooltip" style={{cursor: "pointer", display: 'none'}} onClick={this.handleQuestionReset.bind(null, acquireErrand.data.question_body)}></i>
								</span>;
			if(this.props.wfSettings.alternativeAnswerContainer)
				answerReplyAlternativeAnswer = <div className="col-md-6">
																		<div className="reply-question">
																			<div className="align-left bold">{I('Alternative answers')}</div>
																			<div className="align-right">
																				<LibrarySearch
																					ref={"librarySearch"}
																					defaultLibrary={this.state.currentAreaData.library}
																					onInsert={this.handleAlternativeAnswerInsert}
																					onAppend={this.handleAlternativeAnswerAppend}
																				/>
																			</div>
																			<div className="clear"></div>
																		</div>
																	</div>;

			if(currentAreaData && currentAreaData.templates != null && currentAreaData.templates)
				answerReplyTemplates = <div className="col-md-6">

										<div className="reply-question">
											<div className="align-left bold">{I('Templates')}</div>
											<div className="align-right">
												<a><ImageButton Id="ViewTemplates" pClass={"templates"} title={I("Choose templates")} imageClass="templates" style={{cursor: 'pointer'}} onClick={this.handleTemplates}/>
												</a>
											</div>
											<div className="clear"></div>
										</div>
									</div>;

			if(typeof errand.style !== 'undefined' && Object.keys(errand.style).length > 0){
				var statusClassString="", bgStatusClass="", statusClassText="";
					if( errand.style.themeWarning != 'undefined' &&  errand.style.themeWarning == "System Warning Alert Theme"){
						bgStatusClass="statusWarning";
						statusClassText = I("WARNING");
					}else if( errand.style.themeWarning != 'undefined' &&  errand.style.themeWarning == "System Warning Theme"){
						bgStatusClass = 'statusExpired';
						statusClassText = I("EXPIRED");
					}
			}
			var displayBeautifySubject = acquireErrand.data.answer_subject ? acquireErrand.data.answer_subject : ( acquireErrand.data.subject ? acquireErrand.data.subject : I("No Subject"))
			displayBeautifySubject = replacePM(displayBeautifySubject);

			// /*** Only do when to having several address ***/
			// if(this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL){
			// 	if((errand.data.to).search(",") != -1) {
			// 		var tempAdd = (errand.data.to).split(',')
			// 		for(i=0; i < tempAdd.length; i++) {
			// 			if(i==0) {
			// 				errand.data.to = tempAdd[i].trim();
			// 			} else {
			// 				errand.data.copy += ", "+tempAdd[i].trim();
			// 			}
			// 		}
			// 	}
			// }
			var fromAddress = errand.data.from;
			var copy = errand.data.copy;
			var to = this.getToAddresses(errand.data.to, currentAreaData);
			if( errand.data.service == Workflow.Errand.SERVICE_MANUAL ){
				copy = (extendedData.data.answer_cc ? extendedData.data.answer_cc : "");
			}
			if( (typeof errand.data.secureUserId !== "undefined") &&
				(errand.data.secureUserId.length > 0)){
				fromAddress = errand.data.secureUserId;
			}
			var manualSoNotShow = 'block';
			var staticManualChannel = 'none';
			var manualErrandChannel = false;
			var manualSubItemNotShow = 'inline-block';
			var manualSubItemToShow = 'none';
			if((typeof errand.data.serviceName != "undefined") &&
				((errand.data.serviceName == "SMS") ||
					(errand.data.serviceName == "SMS (Manual)")) &&
				(typeof currentAreaData != "undefined") &&
				(typeof currentAreaData.reply_to_sms != "undefined") &&
				(currentAreaData.reply_to_sms != null)){
				to = currentAreaData.reply_to_sms;
			} else if((typeof errand.data.serviceName != "undefined") &&
				(errand.data.serviceName == "Voice")){
				to = "";
			}
			if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL){
				manualSoNotShow = 'none';
				staticManualChannel = 'inline-block';
				manualSubItemNotShow = 'none';
				manualSubItemToShow = 'inline-blcok';
				manualErrandChannel = true;
			}

			var signaturesSet = [];
			var salutationsSet = [];
			var signUserArea = this.concatUserNAreaSign(currentAreaData);
			signaturesSet = this.getUniqueData(signUserArea);
			var salUserArea = this.concatUserNAreaSal(currentAreaData);
			salutationsSet = this.getUniqueData(salUserArea);
			var ckeSetting = {simpleToolbar: false};
			var bttns = {close: true, sendAll: true, incQuestion: true};
			if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
				bttns.incQuestion = false;
				bttns.close = false;
				bttns.sendAll = false;
				if(this.state.currentReplyChannel == manualFBChannel.idstr ||
					this.state.currentReplyChannel == mechVK.idstr ||
					this.state.currentReplyChannel == mechLINE.idstr ||
					this.state.currentReplyChannel == "3" ||
					this.state.currentReplyChannel == manualTwitter.idstr) {
					ckeSetting.simpleToolbar = true;
				}
			} else {
				if(errand.data.service == Workflow.Errand.SERVICE_VKONTAKTE ||
					errand.data.service == Workflow.Errand.SERVICE_MANUAL_VKONTAKTE ||
					errand.data.service == Workflow.Errand.SERVICE_LINE ||
					errand.data.service == Workflow.Errand.SERVICE_MANUAL_LINE ||
					errand.data.service == Workflow.Errand.SERVICE_MANUAL_FACEBOOK ||
					errand.data.service == Workflow.Errand.SERVICE_FACEBOOK ||
					errand.data.service == Workflow.Errand.SERVICE_TWITTER ||
					errand.data.service == Workflow.Errand.SERVICE_LINKEDIN ||
					errand.data.service == Workflow.Errand.SERVICE_SMS ||
					errand.data.service == Workflow.Errand.SERVICE_MANUAL_SMS ||
					errand.data.service == Workflow.Errand.SERVICE_INSTAGRAM ||
					errand.data.service == Workflow.Errand.SERVICE_MANUAL_TWITTER) {
						bttns.sendAll = false;
						bttns.close = false;
						ckeSetting.simpleToolbar = true;
					/*Mujibur: All the social media channels should have same functionality*/
				}
			}
			var errandNotesCount = this.state.errandNotesCount;
			if(this.props.openedByExternal == true &&
				errandNotesCount == 0){
				errandNotesCount = this.props.errandNotesCount;
			}
			var errorMessageManualErrand = {
				errorMessageSms: this.state.errorMessageSms,
				errorMessageCc: this.state.errorMessageCc,
				errorMessageTo: this.state.errorMessageTo,
				errorMessageBcc: this.state.errorMessageBcc,
				errorMessageEe: this.state.errorMessageEe
			}
			var addresses = {
				to: this.state.selectedReplyAddress,
				cc: this.state.selectedReplyAddressCC,
				bcc: this.state.selectedReplyAddressBCC,
				fwd: this.state.selectedReplyAddressForwardExternal
			};
			var historyChckBx = styleHide;
			var qChckBx = styleHide;
			var colStyle = "col-md-12";
			if(this.state.activateForwardExternal) {
				colStyle = "col-md-11";
				qChckBx = styleDsply;
				if(this.props.historyData &&
					this.props.historyData.length > 0) {
					historyChckBx = styleDsply;
				}
			}
			var externalManualContactCard = ""
			if(typeof this.props.externalManual !=='undefined' &&
				this.props.externalManual == true){
				externalManualContactCard = this.renderContactCard(errand.data.serviceName, errand.data.fromAddress, errand.data.service);
			}
			var controlClassSavedAttachment;
			if(!this.state.toggleSavedAttachments) {
				controlClassSavedAttachment = "fa-plus-square-o";
			} else {
				controlClassSavedAttachment = "fa-minus-square-o";
			}
			var controlClassAction;
			if(!this.state.toggleActionElements) {
				controlClassAction = "fa-plus-square-o";
			} else {
				controlClassAction = "fa-minus-square-o";
			}
			return(
				<div id="content">
					<div className="wrapper">
						<div className="errand-page" id="page-wrapper">
							<div className="row">
								<div className="col-lg-12">
									{this.renderHotKeyComponent()}
									<div id="imgPreviewModal" className="modal fade" tabIndex="-1" role="dialog">
										<div className="modal-dialog" id="imgPreviewModalDialog">
											<div className="modal-content" id="imgPreviewContent">
												<div className="modal-body" id="imagePreviewModalBody">
												<img src="" id="previewImage" />
												</div>
											</div>
										</div>
									</div>
									<ErrandOwnerNotification
										errand={errand}
										errandSrcContext={this.props.currentContextName}
										acquireErrand={acquireErrand}
										wfSettings={this.props.wfSettings}
										areaName={this.state.currentAreaData.name} />

									{this.renderErrandThreads(errand)}
									<div className="panel panel-default">
										<div className="panel-heading">

											<ErrandActionTools
												ref="errActionTools"
												errandSrc="Errand"
												errandSrcContext={this.props.currentContextName}
												externalSystemSettings={openExternalSystemSettings}
												hasErrandsSelected={true}
												hasEELightOn={this.state.eeLightsOn}
												hasErrandNotes={errandNotesCount}
												folders={this.props.wfSettings.userFolders}
												areas={currentAreaData.forward_to_areas}
												agentCanPutBackErrands={this.props.wfSettings.agentCanPutBackErrands}
												agents={currentAreaData.agents}
												wasForwardedToExtenal={this.props.errandInfo.acquireErrand.data.wasForwardedToExternal}
												isClosed={errand.data.closed}
												isAcquired={!(this.props.errandInfo.acquireErrand.acquired == false && this.props.wfSettings.onlyShowErrandIfAcquired == false && this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_NEW)}
												onDelete={this.handleDelete}
												onPrint={this.handlePrint}
												onMoveToArea={this.handleForwardToArea}
												onMoveToFolder={this.handleMoveToFolder}
												onMoveToAgent={this.handleForwardToAgent}
												forwardExternalActived={this.state.activateForwardExternal}
												onForwardToExternal={this.handleForwardToExternal}
												externalAddresses = {this.state.currentAreaData.external_addresses}
												onExternalExpert={this.handleExternalExpert}
												onClose={this.handleClose}
												onLock={this.handleLockToMe}
												onImportant={this.handleOnImportant}
												errandPriority={this.state.priority}
												onErrandNotes={this.handleErrandNotes}
												onOpenExternal={this.handleOpenExternal.bind(null, openExternalSystemSettings)}
												onErrandClose={this.handleErrandClose}
												errandOwner={errand.data.agentId}
												onReturnToInbox={this.handleReturnToInbox.bind(this, this.props.currentContextName, errand.data.agentId)}
												onReturnToSearch={this.handleReturnToSearch.bind(this, this.props.currentContextName, errand.data.agentId)}
												isErrandAcquired={this.props.errandInfo.acquireErrand.acquired}
												onlyShowErrandIfAcquired={this.props.wfSettings.onlyShowErrandIfAcquired}
												openedByExternal={this.props.openedByExternal}
												onQueueToMe={this.handleQueueToMe}
												errandIsSecureMessage={(!this.props.currentErrandData.data.secureUserId ? false : true)}
												/>

											<div className="clear"></div>

											<div className="align-left errand-data">
												<div className={bgStatusClass}>
													<div className={"errand-header"} style={{display: manualSoNotShow}}>
														{statusClassString}
														<h4 dangerouslySetInnerHTML={{__html:ErrandHelper.sanitizeHtml(displayBeautifySubject)}} />
													</div>
													<div style={{display: manualSoNotShow}}>
														<p className="firstColumn"><span className="dataTitle">{I("DATE")} : </span> <span>{errand.data.date}</span></p>
														<p className="topSecondColumn">
															<NicePopover hdr={I("FROM")} txt={fromAddress} keyName="from" />
															{this.renderMembershipStatus()}
														</p>
														{this.renderContactCard(errand.data.serviceName, errand.data.fromAddress, errand.data.service)}
														<p className="thirdColumn">
															<NicePopover hdr={I("COPY TO")} txt={copy} keyName="copy" />
														</p>
														{this.renderDoneDateFeature(errand.data.donedate)}
													</div>
													<div>
														<p className="firstColumn" style={{ display: manualSubItemNotShow }}><span className="dataTitle">{I("ERRAND")} : </span><span>#{errand.data.displayId}</span>
														</p>
														<p className="secondColumn" style={{ display: manualSubItemNotShow }}>
															<NicePopover hdr={I("TO")} txt={to} keyName="to" pos="bottom" />
														</p>
														{this.renderErrandChooseArea('ChooseErrandArea', 'thirdColumn', I('Please choose the area you would like to move the errand to.'), firstAreaItem, currentAreaData.forward_to_areas)}
														{externalManualContactCard}
														<span>
															<ChannelSelection
																errandSrc={this.props.currentContextName}
																errandContextType={this.props.currentContextType}
																serviceName={errand.data.serviceName}
																fromAddress={errand.data.fromAddress}
																service={errand.data.service}
																currentReplyChannel={this.state.currentReplyChannel}
																onChange={this.handleChangeErrandChannel}
																channels = {this.props.channelList}
																wfSettings={this.props.wfSettings}
																externalManual={this.state.externalManual}
																area={currentAreaData} />
														</span>
													</div>
												</div>
												<div className="attachmentContainer" style={{display: manualSoNotShow}}>
													<span className="file-attach">
														<i className="fa fa-paperclip"></i>
														<p dangerouslySetInnerHTML={{__html:ErrandHelper.sanitizeHtml(extendedData.data.errand_attachments)}} />
													</span>
												</div>
												{this.renderErrandTagsSelection(newtaglist)}
											</div>
											<div className="clear"></div>
										</div>
										<div id="ErrandFromEEAnswerReply"></div>
										<div className="panel-body">
											<div className="errand-body">
												<div className="row show-grid">
													<div className="col-md-6" style={{display: manualSoNotShow}}>
														<div className="panel-thread">
															{this.renderIncludeQuestionCheckbox(qChckBx)}
															<div className={"thread-title current-errand "+colStyle}>
																<label style={{marginRight: '10px'}}>{I('Current errand')}</label>
																<span className="align-right">
																	<Feature tag="translation">
																	<i className={"fa fa-globe er-translate " +this.state.visibleTranslationBoxClass} title={I("Translate")} data-toggle="tooltip" onClick={this.showHideTranslation}></i>
																	</Feature>
																	<span id="errandTranslationBox">
																		{this.renderTranslationBox()}
																	</span>
																	{editQuestion}
																</span>
															</div>
															<div className="errandHistoryCheck col-md-1" style={qChckBx}>
															</div>
															<div className={"thread-question-main "+colStyle}>
																<p className="thread-header clearfix">
																	{I('Question')}
																	<span className="align-right">
																	{I("Arrived")} : {errand.data.date}
																	</span>
																</p>
																{this.populateHTML('', 'errandbody', errand.data.body, 1, 999)}
																<span id="TranslationResultBox">
																	{this.showTranslationResult()}
																</span>
															</div>
															<div className="errandHistoryCheck col-md-1" style={historyChckBx}>
																<ErrandThreadCheckbox name="errandSelectAll"
																	id="all" value="errandSelectAll"
																	title={I("Check to select entire thread")}
																	checked={this.props.qhistoryCheckboxes.all}
																	onChckBoxChange={this.props.onChckBoxChange} />
															</div>
															<div className={"thread-title history-errand "+colStyle}>
																{I('History')}
																{this.renderHistorySortOption()}
																<span className="align-right">
																	<i className="fa fa-envelope-o er-history"></i>
																</span>
															</div>
															<div className="errandHistoryCheck col-md-1" style={historyChckBx}>
															</div>
															<div className="errandThreadHistory col-md-12" id="errandThreadHistory">
																<ErrandThreadHistory
																	section={"history"}
																	dataSrc={this.props.historyData}
																	forwardToExternalMode={this.state.activateForwardExternal}
																	onChckBoxChange={this.props.onChckBoxChange}
																	qhistoryCheckboxes={this.props.qhistoryCheckboxes}
																	sortBy={this.state.historySortBy}
																/>
															</div>
														</div>
													</div>
													<div className={this.state.isSticky ? 'col-md-6 fixed' : 'col-md-6'} id="answerBox">
														<div className="panel-reply">
															<div className="reply-title">
																{I('Reply')}
															</div>
															<div className="reply-subject">
																<div className="col-md-1 errand-title">{I('Subject')}</div>
																<div className="col-md-11 errand-subject">
																	<LightCkeditor id="subjectCke" ref="subject"
																		cssClass="formInputSubject" defaultDebug={false}
																		enCkeditor={true} onChange={this.handleSubjectChange}
																		spellLanguages={this.props.wfSettings.answerWysiwyg ? this.props.wfSettings.answerWysiwyg.languageSrc : []}
																		defaultValue={removePM(acquireErrand.data.answer_subject)} />
																</div>
																<div className="clear"></div>
															</div>
															<AnswerReplyOptions
																ref="answerReplyOptions"
																title={this.returnErrandServiceTitle(errand.data.service)}
																wfSettings={this.props.wfSettings}
																currentErrand={errand}
																extendedData={extendedData}
																currentAreaData={currentAreaData}
																acquireErrand={acquireErrand}
																recipientPanelVisibility={this.state.visibleChangeRecepient}
																onChangeEELight={this.changeEELightFromIFrame}
																onHandleTurnLightOffWhenRead={this.handleTurnLightOffWhenRead}
																forwardExternalActived={this.state.activateForwardExternal}
																onChangeRecepientBox={this.handleChangeRecepientBox}
																lock={this.state.lockReplyTo}
																verifyCustomTag={this.verifyCustomTag}
																errorMessageManualErrand={errorMessageManualErrand}
																addresses={addresses}
																onReplyAddress={this.handleReplyAddr}
																onReplyAddressCC={this.handleReplyAddrCc}
																onReplyAddressBCC={this.handleReplyAddrBcc}
																onReplyAddressForwardExternal={this.handleReplyAddrFwdExternal}
																externalAddresses = {this.state.currentAreaData.external_addresses}
																errandService={this.state.currentErrandService}
																onFbHistory={ErrandHelper.handleFbHistory.bind(this, errand.id, errand.data.service)}
																onFbProfile={ErrandHelper.handleFbProfile.bind(this, errand.id, errand.data.service)}
																onFbLike={ErrandHelper.handleFbLike.bind(this, errand.id, errand.data.service)}
																onFbUnlike={ErrandHelper.handleFbUnlike.bind(this, errand.id, errand.data.service)}
																onFbHide={ErrandHelper.handleFbHide.bind(this, errand.id, errand.data.service)}
																onFbUnhide={ErrandHelper.handleFbUnhide.bind(this, errand.id, errand.data.service)}
																onFbDelete={ErrandHelper.handleFbDelete.bind(this, errand.id, errand.data.service)}
																onFbSendPM={ErrandHelper.handleFbSendPM.bind(this, errand.id, errand.data.service)}
																onFbRating={ErrandHelper.handleFbRating.bind(this, errand.id, errand.data.service)}
																onFbDeleteAnswer={ErrandHelper.handleFbDeleteAnswer.bind(this, errand.id, errand.data.service)}
																onFbUpdateAnswer={ErrandHelper.handleFbUpdateAnswer.bind(this, errand.id, errand.data.service)}
																onTwitterRetweet={ErrandHelper.handleTwitterRetweet.bind(this, errand.id)}
																onLinkedinConnect={ErrandHelper.handleLinkedinConnect.bind(this, errand.id)}
																onSMSReplyTo={this.handleReplyAddr}
																onSocialMediaChange={this.handleSocialMediaChange}
																selectedSocialMedia={this.state.selectedSocialMedia}
																errandSrc={this.props.currentContextName}
																errandContextType={this.props.currentContextType}
																toggleSavedAttachments={this.state.toggleSavedAttachments}
																toggleActionElements={this.state.toggleActionElements}
															/>

															<div className="clear"></div>

															<div className="reply-question changeRecipient toggleActionElements" onClick={this.toggleActionElements}>
																<i className={"fa " + controlClassAction}> </i>
																{this.state.toggleActionElements ? 'Hide' : 'Show'} Actions
															</div>
															<div className={this.state.toggleActionElements ? 'renderSalutationAndSignature block' : 'renderSalutationAndSignature none'}>
																<div className="col-md-6">
																	<div className="reply-question">
																		<div className="align-left bold">{I('Salutation')}</div>
																		<div className="align-right">
																			<DropDown id="Salutations"
																				name={I("Salutation")}
																				textNoItem={I("No Salutation")}
																				textChooseItem={I("Choose Salutation")}
																				textNoItemSelected={I("No Salutation selected")}
																				textSelectedItem={I("Selected Salutation")}
																				selectedItems={this.state.selectedSalutations != 0 ? this.state.selectedSalutations : ""}
																				items={salutationsSet}
																				align="left"
																				wantDeselect={true}
																				fields={{id: "id", name: "value"}}
																				onChange={this.handleChangeSalutation}
																			/>
																		</div>
																		<div className="clear"></div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="reply-question">
																		<div className="align-left bold">{I('Signature')}</div>
																		<div className="align-right">
																			<DropDown id="Signatures"
																				name={I("Signature")}
																				textNoItem={I("No Signature")}
																				textChooseItem={I("Choose Signature")}
																				textNoItemSelected={I("No Signature selected")}
																				textSelectedItem={I("Selected Signature")}
																				selectedItems={this.state.selectedSignature != 0 ? this.state.selectedSignature : ""}
																				items={signaturesSet}
																				align="right"
																				wantDeselect={true}
																				fields={{id: "id", name: "value"}}
																				onChange={this.handleChangeSignature}
																			/>
																		</div>
																		<div className="clear"></div>
																	</div>
																</div>
																<div className="clear"></div>
															</div>

															<div className="clear"></div>

															<div className={this.state.toggleActionElements ? 'renderAlternativesAndTemplates block' :'renderAlternativesAndTemplates none'}>
																{answerReplyAlternativeAnswer}
																{answerReplyTemplates}
																<div className="clear"></div>
															</div>

															<div className="clear"></div>
															{this.renderPreviewSalutation()}
															<div className="clear"></div>
															{this.renderCkeditor('AnswerWysiwyg',this.props.wfSettings, acquireErrand, extendedData, errand.data.service, this.state.currentAreaData.library, ckeSetting )}
															<span id="AnswerCharacterCounter"></span>
															<span id="ExternalExpertCopyTextStatus" style={{display:'none'}}></span>
															{this.renderPreviewSignature()}
															<div className="clear"></div>
															<div>
																<div className="errand-attachment" id={"ErrandAttachment"}></div>
															</div>
															<span id="LoadingAttachment" style={{display: 'none'}}></span>
															<div className="reply-question changeRecipient" onClick={this.toggleSavedAttachments}>
																<i className={"fa " + controlClassSavedAttachment}> </i>
																{this.state.toggleSavedAttachments ? 'Hide' : 'Show'} Saved Attachments
															</div>
															{this.state.toggleSavedAttachments ? this.renderAttachmentContainer(mailAttachments) : ''}
														</div>
														{this.renderAttachmentOption(this.props.wfSettings, extendedData)}
														<div className="clear"></div>
														<div className="errand-save-options">
															<div className="col-md-7 nomargin nopadding iconsLeft">
																{this.showAttachmentLink(errand.data.service, errand.data.twitterPm)}
																<span className="divider"> | </span>
																{this.errandsAnswerFeatureAction(this.props.wfSettings, errand, bttns, extendedData, this.props.externalManual)}
															</div>
															<div className="col-md-5 nomargin nopadding textRight">
																{this.renderAllButtons(errand, acquireErrand, bttns)}
															</div>
															<div className="clear"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}else{
			return(	<div id="content">
					<div className="container">
						<div className="row">
							<div className="span12 textAlignCenter">
								<img src="img/loading.gif" />
							</div>
						</div>
					</div>
				</div>
			)
		}
	},
	getToAddresses: function(to, area) {
		if(!to) {
			return "";
		} else if(typeof to !== 'string') {
			return to;
		}
		var tos = to.split(',');
		return this.sortToAddresses(tos, area.serverEmails).join(', ');
	},
	sortToAddresses: function(toEmails, areaEmails) {
		if(!toEmails.length || toEmails.length <= 0) {
			return [];
		}
		// trim and clean
		var trimmed = [];
		$.each(toEmails, function(k,z) {
			trimmed.push(z.trim().toLowerCase());
		});
		toEmails = trimmed;
		if(toEmails.length == 1 || !areaEmails ||
			!areaEmails.length || areaEmails.length <= 0) {
			return toEmails;
		}
		var sorted = [];
		$.each(areaEmails, function(i,v) {
			if(toEmails.length <= 0) {
				return false;
			}
			$.each(toEmails, function(j,w) {
				if(v == w) {
					sorted.push(w);
					toEmails.splice(j, 1);
					return false;
				}
			});
		});
		sorted = sorted.concat(toEmails);
		return sorted;
	},
	handleDueDateChange: function( date ){
		this.state.errandDueDate = date;
	},
	onDeleteDueDate: function(){
		this.state.errandDueDate = ErrandHelper.DATE_NOT_SET;
	},
	verifyCustomTag: function(inputValue, key, optional, allowPasteInput){
		var smsStatus = ErrandHelper.isValidPhoneNo(inputValue);
		var emailStatus = false;
		var errorMessageEmail = I("* You must provide a valid email address.");
		if(optional && inputValue == "") {
			emailStatus = true;
		}else {
			if(allowPasteInput) {
				var emailsArr = inputValue.split(",");
				var invalidEmails = [];
				if(emailsArr.length > 0) {
					for(var i=0;i<emailsArr.length;i++) {
						var email = emailsArr[i].replace(/\s+/g, '');
						emailStatus = ErrandHelper.isValidEmail(email);
						if(!emailStatus) { invalidEmails.push(email); }
					}
					if(invalidEmails.length == 0) {
						emailStatus = true;
					}else {
						var emailsInvalid = invalidEmails.join(",");
						emailStatus = false;
						errorMessageEmail += "\r "+emailsInvalid;
					}
				}
			}else {
				emailStatus = ErrandHelper.isValidEmail(inputValue);
			}
		}
		switch(key){
			case 'replyAddressComboboxSms':
				if(smsStatus){
					this.setState({errorMessageSms: ""});
					return smsStatus;
				}else{
					this.setState({errorMessageSms: I("Please insert valid phone number")});
				}
				break;
			case 'replyAddressCC':
				if(emailStatus){
					this.setState({errorMessageCc: ""});
					return emailStatus
				}else{
					this.setState({errorMessageCc: errorMessageEmail});
				}
				break;
			case 'replyAddressComboboxReplyTo':
				if(emailStatus){
					this.setState({errorMessageTo: ""});
					return emailStatus
				}else{
					this.setState({errorMessageTo: errorMessageEmail});
				}
				break;
			case 'replyAddressBCC':
				if(emailStatus){
					this.setState({errorMessageBcc: ""});
					return emailStatus;
				}else{
					this.setState({errorMessageBcc: errorMessageEmail});
				}
				break;
			case 'replyAddressForwardExternal':
				if(emailStatus){
					this.setState({errorMessageEe: ""});
					return emailStatus;
				}else{
					this.setState({errorMessageEe: errorMessageEmail});
				}
				break;
		}
	},
	handleMoreExcerpt: function(){
		console.log('click read more')
	},
	handleSmsReplyTo: function(items) {
		this.setState({selectedReplyAddress: items,errorMessageSms: ""});
	},
	handleReplyAddr: function(items) {
		this.setState({selectedReplyAddress: items,errorMessageTo: ""});
	},
	handleReplyAddrCc: function(items) {
		this.setState({selectedReplyAddressCC: items,errorMessageCc: ""});
	},
	handleReplyAddrBcc: function(items) {
		this.setState({selectedReplyAddressBCC: items,errorMessageBcc: ""});
	},
	handleReplyAddrFwdExternal: function(items) {
		this.setState({selectedReplyAddressForwardExternal: items,errorMessageEe: ""});
	},
	handleQuestionCheckbox: function(e) {
		this.props.onChckBoxChange('answerquestion', e.target.checked);
	},
	errandsAnswerFeatureAction: function(wfSettings, errand, bttns, extendedData, externalManual){
		if(errand.data.closed)
			return;
		if(errand.data.deleted)
			return;
		return <ErrandAnswerFeatureActions
					wfSettings={wfSettings}
					bttns={bttns}
					extendedData={extendedData}
					externalManual={externalManual}
					onPartialAnswer={this.handlePartialAnswer}
					onErrandLock={this.handleOnLockErrand}
					onErrandLibSuggest={this.handleLibSuggest}
					onErrandIncQuestion={this.handleIncQuestion}
					extFwdActive={this.state.activateForwardExternal}
					includeQ={{handle: this.handleQuestionCheckbox,
						status: this.props.qhistoryCheckboxes.answerquestion}} />
	},
	handlePartialAnswer: function( partial ){
		this.state.partialAnswer = partial;
	},
	handleOnLockErrand: function( lock ){
		this.state.lockToMe = lock;
	},
	handleLibSuggest: function( libSuggest ){
		this.state.librarySuggestion = libSuggest;
	},
	handleIncQuestion: function( incQuestion, status ){
		if(status != undefined){
			if(status == "default"){
				this.state.includeQuestion = true;
			}else if(status == "forward"){
				if(!F('toggleIncludeQuestionDefaultNo')){
					this.state.includeQuestion = true;
				}else{
					this.state.includeQuestion = false;
				}
			}else if(status == "send"){
				this.state.includeQuestion = incQuestion;
			}
		}else{
			this.state.includeQuestion = incQuestion;
		}
		this.state.includeQuestionDefaultNo = F('toggleIncludeQuestionDefaultNo');
	},
	_Cke: function( textareaName ){
		return CKEDITOR.instances[textareaName + "Wysiwyg"];
	},
	clearAnswer: function(){
		if(this._Cke("Answer") )
			this._Cke("Answer").updateElement();
		this._Cke("Answer").setData("");
	},
	renderCkeditor: function(id, wfSettings,  acquireErrand, extendedData, service, libraryId, ckeSetting ){
		var height = features["workflow.answerbox-height"] ? features["workflow.answerbox-height"] : defaultAnswerBoxHeight;
		var fontFamily = (wfSettings.answerWysiwyg ? wfSettings.answerWysiwyg.fontFamily : "");
		var fontSize = (wfSettings.answerWysiwyg ? wfSettings.answerWysiwyg.fontSize : "");
		var langs = (wfSettings.answerWysiwyg ? wfSettings.answerWysiwyg.languageSrc : []);
		var archives = this.state.currentAreaData.file_archive_images;
		var extendedDataArchive = (extendedData && extendedData.area_file_archive? extendedData.area_file_archive : []);
		var areaArchive = (archives != null ? archives : extendedDataArchive);
		return (<Ckeditor
			id={id}
			defaultFontFamily={fontFamily}
			defaultFontSize={fontSize}
			defaultContent={this.state.answerBody}
			spellLanguages={langs}
			showResize={true}
			height={height}
			showLibrary={wfSettings.centionLibrary}
			simpleToolbar={ckeSetting.simpleToolbar != null ? ckeSetting.simpleToolbar : true}
			fileArchiveImages={areaArchive}
			onKeydown={this.handleKeyDown}
			onChange={this.handleCkeditorChange}
			onFocus={this.handleFocusEditor}
			libraryId={libraryId}
			onLibraryClick={this.handleLibraryClick.bind(this, libraryId)}
			onDragnDropFiles={this.handleDragnDropFiles}
		/>);
	},
	handleFocusEditor: function(){
		if(this.refs.librarySearch && this.refs.librarySearch.hideDropDown){
			this.refs.librarySearch.hideDropDown();
		}
	},
	handleLibraryClick: function( ActiveLibraryID, event ){
		var internalLibrary = window.open('workflow#popups/library', 'CentionInternalLibrary', 'scrollbars=yes,menubar=no,toolbar=no,width=768,height=768');
		internalLibrary.focus();
	},
	handleCkeditorChange: function( event ){
		// Ckeditor is uncontrolled component https://facebook.github.io/react/docs/forms.html#uncontrolled-components
		// callback data is already render in the component and can not use setState.
		var oldAnswer = this.state.answerBody;
		this.state.answerBody = event.editor.getData();
		this.ansCkePlainTxt = event.editor.document.getBody().getText();
		this.informTextChange(oldAnswer, this.state.answerBody);
	},
	handleKeyDown: function(event){
		var errand = this.props.currentErrandData.data;
		if( this.props.currentContextName == "Manual Errand" && this.props.currentContextType == "Twitter"){
			errand.service = Workflow.Errand.SERVICE_MANUAL_TWITTER;
		}
		if( errand &&
					( errand.service == Workflow.Errand.SERVICE_TWITTER ||
					errand.service == Workflow.Errand.SERVICE_LINKEDIN ||
					errand.service == Workflow.Errand.SERVICE_FACEBOOK ||
					errand.service == Workflow.Errand.SERVICE_MANUAL_FACEBOOK ||
					errand.service == Workflow.Errand.SERVICE_SMS ||
					errand.service == Workflow.Errand.SERVICE_MANUAL_SMS ||
					errand.service == Workflow.Errand.SERVICE_MANUAL_TWITTER
				))
		{
			var key = event.data.which || event.data.keyCode;
			switch( key ) {
				case 8: //backspace
				case 46: //delete
					this.updateCharacterCounter((errand
						? errand
						: 0),-1);
					break;
				case 37: break; //left
				case 39: break; //right
				case 38: break; //top
				case 40: break; //bottom
				case 36: break; //home
				case 35: break; //end
				case 34: break; //pgDn
				case 33: break; //pgUp
				case 45: break; //insert
				case 93: break; //mouse key
				case 91: break; //windows key
				case 20: break; //CAPSLOCK
				case 27: break; //ESC
				case 9:  break; //Tab
				case 112: break; //F1
				case 113: break; //F2
				case 114: break; //F3
				case 115: break; //F4
				case 116: break; //F5
				case 117: break; //F6
				case 118: break; //F7
				case 119: break; //F8
				case 120: break; //F9
				case 121: break; //F10
				case 122: break; //F11
				case 123: break; //F12
				case 4456466: break; //ALT
				case 1114129: break //CTRL
				case 2228240: break; //SHIFT
				default: this.updateCharacterCounter((errand
						? errand
						: 0),1);
			}
		}
	},
	verifyTwitterPmsUrl: function( errand ){
		function strip(html) {
			var tmp = document.createElement("div");
			tmp.innerHTML = html;
			if (tmp.textContent == "" && typeof tmp.innerText == "undefined") {
				return "";
			}
			return tmp.textContent || tmp.innerText;
		}
		if(errand.service == Workflow.Errand.SERVICE_TWITTER && errand.twitterPm ){
			var body = this._Cke('Answer').getData();
			body = strip(body);
			body = body.replace(/<br>|<br\/>|<br \/>|\r?\n|\s\s$/,'').replace(/&nbsp;/gi, " ");
			if( body.match(/(.*)http([s]?):\/\/.*/) || body.match(/(.*)www.[0-9a-zA-Z',-].*/) ){
				var msg = I('Twitter doesn’t support sending website url’s in Private messages, please remove url to be able to send.');
				alert(msg);
			}
		}
	},
	ckeChangeTxt: function(txt) {
		// this will work as the text is not origin from ckeditor internal
		var oldTxt = this.state.answerBody;
		this.setState({answerBody: txt});
		this.informTextChange(oldTxt, txt);
	},
	informTextChange: function(oldTxt, newTxt) {
		if(oldTxt != newTxt) {
			newTxt = newTxt.replace(/\s/g,'');
			newTxt = newTxt.replace(new RegExp('&nbsp;', 'g'),'');
			newTxt = newTxt.replace(new RegExp('<div></div>','g'),'');
			if(newTxt == ""){
				this.state.ctx.ErrandAnswerUpdated = false;
			} else {
				this.state.ctx.ErrandAnswerUpdated = true;
			}
			WorkflowErrandActions.updateCKE(this.state.ctx);
		}
	},
	updateCharacterCounter: function( errand, increment ) {
		function strip(html) {
			var tmp = document.createElement("div");
			tmp.innerHTML = html;

			if (tmp.textContent == "" && typeof tmp.innerText == "undefined") {
				return "";
			}

			return tmp.textContent || tmp.innerText;
		}

		if( this._Cke('Answer') && document.getElementById('AnswerCharacterCounter') ) {
			var name = (errand ? errand.fromName : '');
			if( increment == undefined ) {
				increment = 1;
			}
			var html = this._Cke('Answer').getData();
			var text = strip(html);
			text = text.replace(/<br>|<br\/>|<br \/>|\r?\n|\s\s$/g,'').replace(/&nbsp;/gi, " ");
			var length = text.length + increment;
			if (length < 0) length = 0;
			var limit = 0;
			switch( errand.service ) {
				case Workflow.Errand.SERVICE_TWITTER:
				case Workflow.Errand.SERVICE_MANUAL_TWITTER:
					var that = this;
					var MPSAL = 1;
					var MPSIGN = 2;
					var sigSalLen = 0;
					function mpsCount( mp ){
						var sal = 0;
						var sig = 0;
						if( mp === MPSAL){
							var salutation = that.salutationBodyContents;
							if(that.salutationBodyContents.indexOf("[name]") != -1 ){
								salutation = that.salutationBodyContents.replace('[name]', name);
							}
							sal = salutation.length;
						}
						if( mp === MPSIGN ){
							var signature = that.signatureBodyContents
							if(that.signatureBodyContents.indexOf("[name]") != -1 ){
								signature = that.signatureBodyContents.replace('[name]', name);
							}
							sig = signature.length;
						}
						return sal + sig;
					};
					sigSalLen = mpsCount(MPSAL) + mpsCount(MPSIGN);
					limit = 280 - (name.length + sigSalLen + 2);
					if( errand.twitterPm ){
						limit = 10000 - (name.length + sigSalLen + 2);
					}
					break;
				case Workflow.Errand.SERVICE_LINKEDIN:	limit = 160; break;
				case Workflow.Errand.SERVICE_FACEBOOK:	limit = 5000; break;
				case Workflow.Errand.SERVICE_VKONTAKTE:	limit = 280; break;
				default:						limit = 0;
			}
			document.getElementById('AnswerCharacterCounter').innerHTML = '' + length + (limit > 0 ? '/' + limit : '');
		}
	},
	showAttachmentLink: function( ch, isTwitter ){
		return <AttachmentLink channel={ch} isTwitter={isTwitter} onClick={this.viewAttachmentAdder}/>;
	},
	handleDragnDropFiles: function( event ){
		if( event.data ){
			var i = 1;
			var fd = new FormData();
			var boundary = Math.floor(Math.random() * 6)+ ''+ i +''+ Math.floor(''+new Date() / 1000) ;
			var fileNameOnly = event.data.name;
			var attachments = [];
			fd.append( 'uploadfile', event.data );
			fd.append( 'fileNameOnly', fileNameOnly);
			fd.append( 'random', parseFloat(boundary));
			/* FIXME: trying to get the file type extension for future use */
			/* For now, when errand viewed, the pasted image file name appear without extension */
			fd.append( 'fileType', event.data.type);
			if(event.data.name != undefined){
				fd.append( 'eventType', 'drag');
			}else{
				fd.append( 'eventType', 'paste');
			}
			$.ajax({
				url: webserverRoot + "errand/uploadAnswerAttachment",
				data: fd,
				processData: false,
				contentType: false,
				type: 'POST',
				success: function( uploadFile ){
					if(event.data.name != undefined){
						this.setImageSrcToCk(uploadFile, event);
					}else{
						if(event.data.type) {
							//TODO: When remove pasted/dragged img away from editor, should remove it's attachment too.
							var fileType = event.data.type;
							var fileExtension = fileType.split('/').pop();
							uploadFile.download = uploadFile.download+"."+fileExtension;
							uploadFile.value = uploadFile.value+"."+fileExtension;
						}
						this.setImageSrcToCk(uploadFile, event);
					}
					attachments.push(uploadFile);
					this.appendEditorAttachments(attachments);
				}.bind(this)
			});
		}
	},
	appendEditorAttachments: function(attachments){
		if (Array.isArray(attachments) == false) {
			return;
		}
		var items = [];
		for (var i = 0; i < attachments.length; i++) {
			this.state.uploadedAttachments.push(parseInt(attachments[i].id, 10));
			items.push({
				id: attachments[i].id,
				name: attachments[i].value,
				value: attachments[i].value,
				estSize: attachments[i].estSize,
				download: attachments[i].download,
				src: attachments[i].download
			});
		}
		ReactDOM.render(
			React.createElement(AgentAttachment, {
				items: items,
				onHandle: function(attachment) {
					this.handleDnDAttachment(attachment, 'del');
					$.post( webserverRoot + "errand/removeTempAttachment", {fid: attachment.id});
					if (this.state.uploadedAttachments.indexOf(attachment.id) !== -1) {
						this.state.uploadedAttachments.splice(this.state.uploadedAttachments.indexOf(attachment.id), 1);
					}
				}.bind(this)
			}),
			document.getElementById('ErrandAttachment')
		);
	},
	setImageSrcToCk: function( img, editor ){
		if(img.download.match(/\.(jpg|jpeg|png|gif)$/)){
			if(this._Cke("Answer") )
				this._Cke("Answer").updateElement();
			var context = '<img src="'+ img.download +'" alt="" />';
			this._Cke("Answer").insertHtml(context);
		}
	},
	handleDnDAttachment: function( attachment, todo ){
		var attachmentId = parseInt(attachment.id, 10);
		if(todo === 'add')
			this.state.uploadedAttachments.push(attachmentId);
		if(todo === 'del'){
			if(this.state.uploadedAttachments.indexOf(attachmentId) !== -1)
				this.state.uploadedAttachments.splice(this.state.uploadedAttachments.indexOf(attachmentId), 1);
		}
	},
	viewAttachmentAdder: function(item){
		this.renderAttachmentAdder();
	},
	renderAttachmentAdder:function(){
		var extendedData = this.props.errandInfo.extendedData.data;
		var archives = this.state.currentAreaData.file_archive_images;
		var extendedDataArchive = (extendedData && extendedData.area_file_archive? extendedData.area_file_archive : []);
		var areaArchive = (archives != null ? archives : extendedDataArchive);
		this.state.visibleAttachmentBox = !this.state.visibleAttachmentBox;
		return(
			ReactDOM.render(<AddNewAttachmentBox
				visibleAttachmentBox={this.state.visibleAttachmentBox}
				areaFileArchive={areaArchive}
				wfSettings={this.props.wfSettings}
				onUploadFinish={this.handleAddNewAttachmentChange}
				onArchiveAttachFinish={this.handleArchiveAttachment} />,
				document.getElementById("ArchiveAndBrowseBox"))
		);
	},
	handleAddNewAttachmentChange: function( attachment, todo ){
		var attachmentId = parseInt(attachment.id, 10);
		if(todo === 'add')
			this.state.uploadedAttachments.push(attachmentId);
		if(todo === 'del'){
			if(this.state.uploadedAttachments.indexOf(attachmentId) !== -1)
				this.state.uploadedAttachments.splice(this.state.uploadedAttachments.indexOf(attachmentId), 1);
		}
		this.renderAttachmentAdder();
	},
	handleArchiveAttachment: function( attachment, todo ){
		var attachmentId = attachment.id;
		if(todo === 'add')
			this.state.archiveAttachments.push(attachmentId);
		if(todo === 'del'){
			attachmentId = attachment;
			if(this.state.archiveAttachments.indexOf(attachmentId) !== -1)
				this.state.archiveAttachments.splice(this.state.archiveAttachments.indexOf(attachmentId), 1);
		}
		this.renderAttachmentAdder();
	},
	concatUASalCont: function( currentAreaData ){
		var salCont = [];
		if( currentAreaData != null ){
			if( currentAreaData.salutation_contents != null ){
				salCont = currentAreaData.salutation_contents;
			}
			if( currentAreaData.user_salutations_content != null ){
				if( salCont.length > 0 )
					salCont = salCont.concat( currentAreaData.user_salutations_content );
				else
					salCont = currentAreaData.user_salutations_content;
			}
		}
		return salCont;
	},
	handleChangeSalutation: function(selectedItem) {
		if(selectedItem < 1) {
			this.setState({selectedSalutations: 0});
		} else {
			this.setState({selectedSalutations: selectedItem});
		}
	},
	handleChangeQuickTags: function(tags){
		this.setState({
			selectedTags: tags,
			initiallyToggleDropdown: features["do-not-close-tags-drop-down"]
		});
	},
	handleSubjectChange: function(event) {
		//event.preventDefault();
		/* Mujibur: I kept that for temporarily: still testing someparts of it.Will remove later.
			this.props.onChange({value: event.target.value});
		*/

		//this.state.subject = event.target.value;
	},
	handleSearchBox: function() {
		//Handle Search Box
		console.log('change search box')
	},
	concatUASignCont: function( currentAreaData ){
		var sgCont = [];
		if( currentAreaData != null ){
			if( currentAreaData.signature_contents != null ){
				sgCont = currentAreaData.signature_contents;
			}
			if( currentAreaData.user_signatures_content != null ){
				if( sgCont.length > 0 )
					sgCont = sgCont.concat( currentAreaData.user_signatures_content );
				else
					sgCont = currentAreaData.user_signatures_content;
			}
		}
		return sgCont;
	},
	handleChangeSignature: function(selectedItem){
		if(selectedItem < 1) {
			this.setState({selectedSignature: 0});
		} else {
			this.setState({selectedSignature: selectedItem});
		}
	},
	updateCKBody: function( tc ){
		this._Cke('Answer').setData(this._Cke('Answer').getData() + tc);
		this._Cke('Answer').updateElement();
	},
	handleTemplates: function(e){
		var activeAreaID = (this.state.areasForErrand != "" ? this.state.areasForErrand : 0);
		var errand = this.props.currentErrandData.data;
		if(activeAreaID == 0){
			activeAreaID = errand.area;
		}
		var onInsertTemplate = function(template){
			let tc = $('' + template.Content).text();
			let t = '[' + template.Name + ']';
			if( this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ){
				if(errand.service != Workflow.Errand.SERVICE_TWITTER)
					this.updateCKBody(t);
				else{
					this.updateCKBody(tc);
					this.updateCharacterCounter((errand	? errand : 0) , 1);
				}
			}else{
				if(this.props.currentContextType == manualTwitter.manualCtx){
					this.updateCKBody(tc);
					this.updateCharacterCounter((errand	? errand : 0) , 1);
				}else{
					this.updateCKBody(t);
				}
			}
		}.bind(this);
		ErrandHelper.loadTemplates({x:e.clientX,y:e.clientY-200,areaId:activeAreaID,onInsertTemplate:onInsertTemplate});
	},
	populateHTML: function(title, id, value, option, subOption){
		return(
			<div className="span3">
				<div className="widget">
					<div className="widget-content">
						<h4>{title}</h4>
						{(option == this.props.OPTIONPLAINTEXT ?
							<div id="editableBody" dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(value)}}></div>
							: this.renderComponent(subOption, id, value, I('Choose {TITLE}').replace('{TITLE}', title))
						)}
					</div>
				</div>
			</div>
		);
	},
	renderComponent: function(subOption, id, value, firstOptionText){
		return(<div className="control-group">
				<div className="controls">
					{(subOption == this.props.SUBOPTIONTEXTFIELD ? this.renderTextField(id, value) : (subOption == this.props.SUBOPTIONTEXTAREA ? this.renderTextArea(id, value) : (subOption == this.props.SUBOPTIONSELECT ? this.renderSelect(id, firstOptionText , value) : (subOption == this.props.SUBOPTIONCHECKBOX ? "Checkbox" : (subOption == this.props.SUBOPTIONRADIO ? "RADIO" : (subOption == this.props.SUBOPTIONBUTTON ? "Button" : "Empty") )))))}
				</div>
			</div>
		);
	},
	renderTextArea: function(id, value, placeholder, height){
		return(
			<textarea onChange={this.handleTextareaValue} ref={id} style={{width: '100%', height: height}} id={id} defaultValue={value} placeholder={placeholder} />
		);
	},
	renderSelect: function(id, firstItemText, opts){
		return(
				<CentionPopup
					id={id}
					ref={id}
					onChange={this.handleChange}
					src={opts}
					firstItem={firstItemText}
					multiple={false} />
			);
	},
	addErrandAttachmentToAnswer: function(wfSettings) {
		var d = this.props.errandInfo.extendedData.data;
		if(wfSettings.attachmentListErrandShowStatus && d) {
			var a = d.answer_errand_attachments;
			if(a && a.length > 0) {
				return (
					<div className="col-md-7">
						<ErrandQuestionAttchments
							attachments={a}
							selectedAttachments={this.state.questionAttachments}
							onSelectedAttach={this.handleQuestionAttach} />
					</div>
				);
			}
		}
		return null;
	},
	handleQuestionAttach: function(checked, selectType, value) {
		if(selectType === 'all') {
			if(checked) {
				this.selectAllQuestionAttachments();
			} else {
				this.setState({questionAttachments: []});
			}
			return;
		}
		var res = {found: false, index: 0};
		$.each(this.state.questionAttachments, function(i,v) {
			if(value == v) {
				res.found = true;
				res.index = i;
				return false;
			}
		});
		if(checked) {
			if(!res.found) {
				this.setState({questionAttachments:
					update(this.state.questionAttachments, {$push: [value]})});
			}
		} else {
			if(res.found) {
				this.setState({questionAttachments:
					update(this.state.questionAttachments, {$splice: [[res.index, 1]]})});
			}
		}
	},
	selectAllQuestionAttachments: function() {
		var ext = this.props.errandInfo.extendedData,
			wf = this.props.wfSettings;
		if(wf && wf.attachmentListErrandShowStatus && ext) {
			var d = ext.data;
			if(d) {
				var a = d.answer_errand_attachments;
				if(a && a.length && a.length > 0) {
					var all = [];
					$.each(a, function(i,v) {
						all.push(v.id);
					});
					this.setState({questionAttachments: all});
				}
			}
		}
	},
	handleOpenExternalExpertPagePopup: function(){
		var parseJson = this.fetchWFSettingsFroCache();
		var userID = parseJson.activeUserId;
		var authToken = parseJson.activeUserAuthToken;
		var ActiveErrandID = this.props.currentErrandId;
		var activeModal = this.state.targetModal;
		var ErrandHasExternalExpertQueries = this.props.errandInfo.acquireErrand.data.errand_externalexperts;
		var url = process.env.PATH_PREFIX + '/externalexpert#edit/' + ActiveErrandID;
		if( ErrandHasExternalExpertQueries ) {
			url = process.env.PATH_PREFIX + '/externalexpert#list/' + ActiveErrandID;
		}
		if( userID && authToken ) {
			url += '/auth/' + userID + '/' + authToken;
		}
		if(typeof this.props.openedByExternal !== "undefined" &&
			this.props.openedByExternal == true){
			url += '/openedByExternal';
		}
		var gourl = urlForApplicationAction(url);
		if(this.props.wfSettings.applySolidusWorkaround == true) {
			var jqiFrame = $('<iframe name="jqiframe" height="500" width="730" border="0" scrolling="yes" id="jqiframe" src="'+ gourl +'"></iframe>');
			$( "#dialogErrandHistoryPopup" ).html("");
			$( "#dialogErrandHistoryPopup" ).attr('title',I('External Expert'));
			$( "#dialogErrandHistoryPopup" ).append(jqiFrame).dialog({
				width: 750,
				dialogClass: "jqiFrame",
				modal: true
			});
		} else {
			var popup = window.open(urlForApplicationAction(url),'errand ' +
				this.props.currentErrandId,
				'width=1000,height=640,scrollbars=yes,status=yes');
			if( popup && popup.focus ) {
				popup.focus();
			}
		}
	},
	handleSocialMediaChange: function(value) {
		this.state.selectedSocialMedia = value;
	},
	handleTurnLightOffWhenRead: function(){
		if(this.state.eeLightsOn == "activated"){
			$.ajax({
					url: webserverRoot + "externalexpert/lightWithAction",
					data: { errand : this.props.currentErrandId },
					type: 'GET',
					success: function(data) {
						if(data.status == "light is off") {
							$('#lightOnOffbtn').text(I('Turn Light On'));
							$('#lightStatus').show();
							$('#lightStatus').html(I('Light has been turned off.'))
							this.state.eeLightsOn = "active";
							this.refs.errActionTools.handleEESwitch(this.state.eeLightsOn);
						}
					}.bind(this)
			});
			setTimeout(function(){
				$('#lightStatus').hide();
			}.bind(this), 3000);
		}
	},
	handleTurnExternalExpertLightsOff: function(){
		$.ajax({
				url: webserverRoot + "externalexpert/lightWithAction",
				data: { errand : this.props.currentErrandId },
				type: 'GET',
				success: function(data) {
					if(data.status == "light is off") {
						$('#lightOnOffbtn').text(I('Turn Light On'));
						$('#lightStatus').show();
						$('#lightStatus').html(I('Light has been turned off.'))
						this.state.eeLightsOn = "active";
						this.refs.errActionTools.handleEESwitch(this.state.eeLightsOn);
					}
					if(data.status == "light is on") {
						$('#lightOnOffbtn').text(I('Turn Light Off'));
						$('#lightStatus').show();
						$('#lightStatus').html(I('Light has been turned on.'))
						this.state.eeLightsOn = "activated";
						this.refs.errActionTools.handleEESwitch(this.state.eeLightsOn);
					}
				}.bind(this)
		});
		setTimeout(function(){
			$('#lightStatus').hide();
		}.bind(this), 3000);
	},
	handleClose: function(){
		ErrandHelper.closeErrand({
				areaData:this.state.currentAreaData,
				areaDatas:this.state.currentAreaDatas,
				currentContextName: this.props.currentContextName,
				associated_list: this.state.associated_list,
				currentContext:this.props.currentErrandData,
				errandInfo:this.props.errandInfo,
				selected_normal_tags:[]
			},
			this.createUpdateErrandObject( false ),
			function(){
				if((typeof this.props.openedByExternal !== "undefined") &&
					(this.props.openedByExternal == true)){
					try {
						window.close();
					} catch (e) {
						console.log("dbg: unable to close browser window: ", e);
					}
					window.location.href = "about:blank";
				} else {
					this.backToPreviousPage();
				}
			}.bind(this));
	},
	handleDelete: function(){
		var errandList = this.getErrandListForAction();
		ErrandHelper.deleteErrands({
				errandListToDelete: errandList,
				areaIds: [this.state.currentAreaData.id],
				openedByExternal: this.props.openedByExternal,
				isClearIT: this.props.isClearIT
			},
			function() {
				if((typeof this.props.openedByExternal !== "undefined") &&
					(this.props.openedByExternal == true)){
					try {
						window.close();
					} catch (e) {
						console.log("dbg: unable to close browser window: ", e);
					}
					window.location.href = "about:blank";
				} else {
					this.backToPreviousPage();
				}
			}.bind(this)
		);
	},
	handlePrint: function(action){
		ErrandHelper.printErrands([this.props.currentErrandId], action, function(){
			//do nothing
		}.bind(this));
	},
	handleForwardToArea: function( item ){
		var errandList = this.getErrandListForAction();
		if(this.checkAndLockTranscation("forward to area") == false) return
		ErrandHelper.forwardErrandsToArea(item, errandList, true, this.props.openedByExternal, function(){
			this.unlockTransaction();
			if((typeof this.props.openedByExternal !== "undefined") &&
				(this.props.openedByExternal == true)){
				try {
					window.close();
				} catch (e) {
					console.log("dbg: unable to close browser window: ", e);
				}
				window.location.href = "about:blank";
			} else {
				this.backToPreviousPage();
			}
		}.bind(this), this.createUpdateErrandObject(), true);
	},
	handleMoveToFolder: function( item ){
		var errandList = this.getErrandListForAction();
		if(this.checkAndLockTranscation("move to folder") == false) return
		ErrandHelper.moveErrandsToFolder(item, errandList, true, this.props.openedByExternal, function(result){
			this.unlockTransaction();
			if(result.sucess != undefined && !result.success){
				alert(result.message || I("Unable to process the request"));
			}
			this.backToPreviousPage();
		}.bind(this), this.createUpdateErrandObject());
	},
	handleForwardToAgent: function( item ){
		var errandList = this.getErrandListForAction();
		if(this.checkAndLockTranscation("foward to agent") == false) return
		ErrandHelper.forwardErrandsToAgent(item, errandList, this.props.openedByExternal, function(){
			this.unlockTransaction();
			if((typeof this.props.openedByExternal !== "undefined") &&
				(this.props.openedByExternal == true)){
				try {
					window.close();
				} catch (e) {
					console.log("dbg: unable to close browser window: ", e);
				}
				window.location.href = "about:blank";
			} else{
				this.backToPreviousPage();
			}
		}.bind(this), this.createUpdateErrandObject());
	},
	getErrandListForAction: function() {
		var errandList = [];
		var currentId = this.props.currentErrandId;
		errandList.push(currentId);
		this.state.associated_list.forEach(function( al ){
			errandList.push(al.id)
		});
		return errandList
	},
	handleReturnToInbox: function(srcContext, owner){
		var openedByExternal = false;
		if (this.props.wfSettings.externalQueue == "puzzel"){
			if (confirm("Are you sure you want to return errand to Inbox?")
						== false){
				return;
			}
		}
		if((typeof this.props.openedByExternal !== "undefined") &&
					(this.props.openedByExternal == true)){
			openedByExternal = true;
		}
		if( srcContext != ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH ){
			ErrandHelper.returnErrandToInbox(
				this.getErrandListForAction(),
				this.props.currentErrandId,
				openedByExternal,
			function(response){
				if(openedByExternal){
					var allReturned = false;
					var message;
					response.mcam.channels.forEach(function(d) {
						if(typeof d.type != undefined &&
							d.type == "Result") {
							var returnStruct = JSON.parse(d.content);
							allReturned = returnStruct.allReturned;
							message = returnStruct.message;
						}
					}.bind(this));
					if(allReturned == false) {
						alert(message);
					} else {
						try {
							window.close();
						} catch (e) {
							console.log("dbg: unable to close browser window: ", e);
						}
						window.location.href = "about:blank";
					}
				} else {
					this.backToPreviousPage();
				}
			}.bind(this));
		}else{
			if( owner == 0 ){
				ErrandHelper.returnErrandToInbox(
					this.getErrandListForAction(),
					this.props.currentErrandId,
					openedByExternal,
				function(){
					window.location.hash= ErrandHelper.getCacheBackTo();
				}.bind(this));
			}
			else {
				if(owner  == F('activeUserId')) {
					ErrandHelper.returnErrandToInbox(
						this.getErrandListForAction(),
						this.props.currentErrandId,
						openedByExternal,
					function(){
						window.location.hash= ErrandHelper.getCacheBackTo();
					}.bind(this));
				} else {
					window.location.hash= ErrandHelper.getCacheBackTo();
				}
			}
		}
	},
	handleReturnToSearch: function(srcContext, owner){
		if( srcContext != ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH ){
			window.location.hash= ErrandHelper.getCacheBackTo();
		}else{
			if( owner == 0 ){
				window.location.hash= ErrandHelper.getCacheBackTo();
			}
			else {
				if(owner  == F('activeUserId')) {
					window.location.hash = ErrandHelper.getCacheBackTo();
				} else {
					window.location.hash = ErrandHelper.getCacheBackTo();
				}
			}
		}
	},
	isOwnEmail: function(emailToBeChecked, area) {
		if(!emailToBeChecked || !area) {
			return false;
		}
		emailToBeChecked = emailToBeChecked.trim().toLowerCase();
		if(area.reply_to_address) {
			if(area.reply_to_address == emailToBeChecked) {
				return true;
			}
		}
		if(!area.serverEmails || !area.serverEmails.length) {
			return false;
		}
		var positiveResult = false;
		$.each(area.serverEmails, function(i,v) {
			if(v == emailToBeChecked) {
				positiveResult = true;
				return false;
			}
		});
		return positiveResult;
	},
	destroySpellChecker: function(){
		let cke = this._Cke("Answer");
		if(typeof cke !== 'undefined'){
			let spc = cke.commands.spellchecker;
			if( typeof spc !== 'undefined' && spc.state === 1){
				cke.plugins.cention_spellcheck.destroy();
			}
		}
	},
	createUpdateErrandObject: function( sendAnswerToAll ){
		var o = {};
		o.current_context_name = this.props.currentContextName;
		this.destroySpellChecker();
		var associated_list = [];
		for (var i = 0; i < this.state.associated_list.length; i++) {
			//current errand removed from associate list
			if(this.state.associated_list[i].id != this.props.currentErrandData.data.id){
				associated_list.push(this.state.associated_list[i].id);
			}
		}
		o.associated_list = associated_list.join(',');
		o.update_area_id = this.state.areasForErrand;
		if((o.update_area_id == "") &&
			(typeof this.props.currentErrandData.data.area !== 'undefined'))
		{
			o.update_area_id = this.props.currentErrandData.data.area;
		}
		o.update_id = this.props.currentErrandData.data.id;

		if(this.state.currentErrandService > 0) {
			o.update_channel = this.state.currentErrandService;
		} else {
			o.update_channel = this.props.currentErrandData.data.service;
		}
		if(this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			o.update_from = this.props.currentErrandData.data.from;
		} else {
			// here is the trick to make sure the email manual
			// errand.Mail.From populated with recepient address even though
			// the receipient not the one who send errand to C3.
			/* FIXME:(Mujibur) its needs to change with proper from address */
			if( this.state.selectedReplyAddress.length > 0 )
				o.update_from = this.state.selectedReplyAddress[0].id;
		}
		var update_tags = [];
		if (Array.isArray(this.state.selectedTags)) {
			this.state.selectedTags.forEach(function(group) {
				group.forEach(function(tag) {
					update_tags.push(tag.id);
				});
			});
		}
		o.update_tags = update_tags.join( ',' )
		o.selected_group_tags = this.state.selectedTags;
		var trim = this.ansCkePlainTxt.replace(/^\s+|\s+$/, '');
		if(trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) {
			if(/^(<div>[\s\xA0]*<\/div>\s*)+$/.test(this.state.answerBody)) {
				o.update_answer = "";
			} else {
				o.update_answer = this.state.answerBody;
			}
		} else {
			o.update_answer = this.state.answerBody;
		}
		o.update_subject = this.refs.subject.GetValue();
		o.update_attachment_total = 0;//added to use in ErrandHelper equivalent to  _('AnswerAttachmentList').getAttachmentTotal()
		o.update_salutation = this.state.selectedSalutations;
		o.update_signature = this.state.selectedSignature;

		// update_to is different from update_from where update_to is to
		// fill up answer.Response.To field and not errand.Mail. update_from
		// on the other hand is filling errand.Mail.From. So, when come to
		// email manual errand update_from and update_to should have the
		// same value.
		var update_to = [];
		var update_cc = [];
		var update_bcc = [];
		var update_forward = [];

		if (!this.props.currentErrandData.data.secureUserId) {
			if (this.state.selectedReplyAddress.length > 0) {
				this.state.selectedReplyAddress.map(function(item) {
					update_to.push(item.id);
				});
			}

			if (this.state.selectedReplyAddressCC.length > 0) {
				this.state.selectedReplyAddressCC.map(function(item) {
					update_cc.push(item.id);
				});
			}

			if (this.state.selectedReplyAddressBCC.length > 0) {
				this.state.selectedReplyAddressBCC.map(function(item) {
					update_bcc.push(item.id);
				});
			}

			if (this.state.selectedReplyAddressForwardExternal.length > 0) {
				this.state.selectedReplyAddressForwardExternal.map(function(item) {
					update_forward.push(item.id);
				});
			}
			if (sendAnswerToAll) {
				var addressLists = [];
				addressLists.push(this.props.currentErrandData.data.toAddresses);
				addressLists.push(this.props.currentErrandData.data.copyAddresses);
				for (var i = 0; i < addressLists.length; i++) {
					if (Array.isArray(addressLists[i])) {
						for (var j = 0; j < addressLists[i].length; j++) {
							var email = addressLists[i][j];
							if(!this.isOwnEmail(email, this.state.currentAreaData)) {
								update_cc.push(email);
							}
						}
					}
				}
			}
		} else {
			update_to.push(o.update_from);
		}

		o.update_to = update_to.join( ',' )
		o.update_cc = update_cc.join( ',' )
		o.update_bcc = update_bcc.join( ',' );
		o.update_forward = update_forward.join( ',' );

		o.done_date = this.state.errandDueDate;

		if( (this.props.currentErrandData.data.service == Workflow.Errand.SERVICE_SMS) &&
			(this.props.errandInfo.extendedData.data.errand_type == Workflow.Mail.TYPE_SMS)){
			o.response_type = Workflow.Mail.TYPE_SMS;
			o.response_channel = Workflow.Errand.SERVICE_SMS;
		}
		o.update_lock = this.state.lockToMe;
		o.update_close = this.state.partialAnswer;
		o.update_priority = this.state.priority;
		o.update_library = this.state.librarySuggestion;
		o.update_include_question = this.props.qhistoryCheckboxes.answerquestion;
		if(this.state.activateForwardExternal){
			if (this.props.qhistoryCheckboxes.all) {
				o.update_history = 'all';
			} else {
				var selectedErrands = [];
				if (this.props.qhistoryCheckboxes.question) {
					selectedErrands.push(this.props.currentErrandData.data.id);
				}
				$.each(this.props.qhistoryCheckboxes, function(k, v) {
					if(k !== 'all' && k !== 'question' &&
						k !== 'answerquestion' &&
						k !== this.props.currentErrandData.data.id.toString()) {
						if(v) {
							selectedErrands.push(k);
						}
					}
				}.bind(this));
				o.update_history = selectedErrands.join(',');
			}
			// this is just hack to make sure the tag exclude question
			// is not in errand for external forward to send out.
			o.update_include_question = true;
		}
		if (F('edit-question')) {
			o.update_question = document.getElementById('editableBody').innerHTML;
		} else {
			o.update_question = this.props.currentErrandData.data.body;
		}

		var questionToRemoveAttr = o.update_question.replace(/data-reactid="([0-9.":$\\s-]+)/gi, "");
		o.update_question = questionToRemoveAttr;
		if(o.update_answer_forward){
			o.update_answer_forward = o.update_answer_forward.replace(/data-reactid="([0-9.":$\\s-]+)/gi, "");
		}
		o.update_question_subject = this.props.errandInfo.acquireErrand.data.answer_subject ? this.props.errandInfo.acquireErrand.data.answer_subject : I("No Subject");
		o.update_uploaded_attachments = "0,";
		if( this.state.uploadedAttachments.length > 0 )
			o.update_uploaded_attachments += this.state.uploadedAttachments.join(",");
		if( this.state.archiveAttachments.length > 0 )
			o.update_archive_attachments = this.state.archiveAttachments.join(",");
		if(this.state.questionAttachments.length > 0)
			o.update_errand_attachments_with_mail = this.state.questionAttachments.join(",");
		if(this.state.libraryAttachments.length > 0)
			o.update_library_attachments = this.state.libraryAttachments.join(",");
		o.external_expert_copy_text_status = document.getElementById('ExternalExpertCopyTextStatus').innerHTML;
		o.update_external_expert_Answer_attachments = this.getExternalExpertAnswerAttachment();
		//Facebook wall message(Facebook Manual errand)
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL && this.state.currentReplyChannel == manualFBChannel.idstr){
			if( this.state.selectedSocialMedia != 0 ){
				var fbIdAndAccountId = this.state.selectedSocialMedia.split('#');
				o.update_from = fbIdAndAccountId[1];
				o.update_facebook_account = fbIdAndAccountId[0];
				/*Manual Facebook errands to and from is same */
				o.update_to = fbIdAndAccountId[1];
				o.update_close = false;
				o.update_channel = Workflow.Errand.SERVICE_MANUAL_FACEBOOK;
				o.update_question_subject = o.update_subject;
			}
		}
		// VK manual errand - wall posting
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.state.currentReplyChannel == mechVK.idstr) {
			if(this.state.selectedSocialMedia != 0) {
				var vkIdAndAccountId = this.state.selectedSocialMedia.split('#');
				o.update_from = vkIdAndAccountId[1];
				o.update_facebook_account = vkIdAndAccountId[0];
				o.update_to = vkIdAndAccountId[1];
				o.update_close = false;
				o.update_channel = Workflow.Errand.SERVICE_MANUAL_VKONTAKTE;
				o.update_question_subject = o.update_subject;
			}
		}
		// here is to handle manual LINE which need LINE account drop down box selection
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.state.currentReplyChannel == mechLINE.idstr) {
			if(this.state.selectedSocialMedia) {
				var lineaccount = this.state.selectedSocialMedia.split('#');
				// update_from: unlike fb requires user input - which is
				// the errand.Mail.From field and is filled with recepient
				// LINE address.
				// update_facebook_account: unlike the name suggest, this is
				// used for LINE channel too.
				o.update_facebook_account = lineaccount[0];
				// update_to: this is to fill errand.Answer.Response.To
				// field. In manual errand update_from will be same as
				// update_to.
				// update_close: partial answer is allowed
				o.update_channel = Workflow.Errand.SERVICE_MANUAL_LINE;
				o.update_question_subject = o.update_subject; // this is needed when shown up in Search
			}
		}
		//Twitter Manual errand
		if(	this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
			this.state.currentReplyChannel == manualTwitter.idstr){
			if( this.state.selectedSocialMedia != 0 ){
				var twIdAndAccountId = this.state.selectedSocialMedia.split('#');
				o.update_from = twIdAndAccountId[1];
				o.update_facebook_account = twIdAndAccountId[0];
				/*Manual twitter errands to and from is same */
				o.update_to = twIdAndAccountId[1];
				o.update_close = false;
				o.update_channel = Workflow.Errand.SERVICE_MANUAL_TWITTER;
				o.update_question_subject = o.update_subject;
			}
		}
		if((typeof this.props.externalManual !== "undefined") &&
			(this.props.externalManual == true)){
			o.external_manual = true
			o.update_question_subject = o.update_subject;
		}else{
			if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL){
				o.update_question_subject = o.update_subject;
			}
		}
		if(typeof this.props.openedByExternal !== "undefined"){
			o.openedByExternal = this.props.openedByExternal
		}
		console.log('dbg: update object:', o);
		return o;
	},
	warnEmptySubject: function() {
		if(this.refs.subject.GetValue() == "") {
			return window.confirm(I("Are you sure you want to send without a subject?"));
		}
		return true;
	},
	areaHasChannel: function(ch) {
		var areaData = this.state.currentAreaData;
		switch(ch) {
			case Workflow.Errand.SERVICE_LINE:
			case Workflow.Errand.SERVICE_MANUAL_LINE:
				if(areaData.lineAccounts &&
					typeof areaData.lineAccounts.length !== "undefined" &&
					areaData.lineAccounts.length &&
					areaData.lineAccounts.length > 0) {
						return true;
				}
				break;
			case Workflow.Errand.SERVICE_VKONTAKTE:
			case Workflow.Errand.SERVICE_MANUAL_VKONTAKTE:
				if(areaData.vkAccounts &&
					typeof areaData.vkAccounts.length !== "undefined" &&
					areaData.vkAccounts.length &&
					areaData.vkAccounts.length > 0) {
						return true;
				}
				break;
			case Workflow.Errand.SERVICE_FACEBOOK:
			case Workflow.Errand.SERVICE_MANUAL_FACEBOOK:
				if(areaData.fbAccounts &&
					typeof areaData.fbAccounts.length !== "undefined" &&
					areaData.fbAccounts.length &&
					areaData.fbAccounts.length > 0) {
						return true;
				}
				break;
			case Workflow.Errand.SERVICE_TWITTER:
			case Workflow.Errand.SERVICE_MANUAL_TWITTER:
				if(areaData.twAccounts &&
					typeof areaData.twAccounts.length !== "undefined" &&
					areaData.twAccounts.length &&
					areaData.twAccounts.length > 0) {
						return true;
				}
				break;
		}
		return false;
	},
	warnAreaCh: function(ch) {
		// I("Please select an area with a VK account.")
		// I("Please select an area with a LINE account.")
		// I("Please select an area with a Facebook account.")
		alert(I("Please select an area with a {SOCIALMEDIA} account.")
			.replace("{SOCIALMEDIA}", ch));
	},
	fromManualPage: function(){
		var originatedFrom = ['#facebook', '#manual', '#vkontakte'];
		return originatedFrom.indexOf(window.location.hash) != -1
	},
	chckSocialCh: function(res, ch) {
		// ch must has {abbr: string, type: integer}
		var warnAcc = I("Please choose an account to proceed.");
		if(!this.state.selectedSocialMedia && !this.fromManualPage()) {
			alert(warnAcc);
			res.result = false;
		} else if(!this.areaHasChannel(ch.type)) {
			this.warnAreaCh(ch.abbr);
			res.result = false;
		}
	},
	chckConditions: function(res, act) {
		// must mutate the res directly
		var warnToAddress = I("You have to fill in a To address to send.");
		var warnArea = I("Please select an area to proceed.");
		res.result = true;
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			if(!this.state.areasForErrand || this.state.areasForErrand == "0") {
				alert(warnArea);
				res.result = false;
			} else if(this.state.currentReplyChannel == "") {
				alert(I("Invalid channel! Please verify."));
				res.result = false;
			} else if(this.state.currentReplyChannel != mechVK.idstr &&
				this.state.currentReplyChannel != manualFBChannel.idstr &&
				this.state.currentReplyChannel != manualTwitter.idstr) {
				// filter condition for email, sms, LINE, etc
				// selectedReplyAddress defaulted to [""] but component
				// return [] when empty.
				var ra = this.state.selectedReplyAddress;
				if(!ra.length || ra.length <= 0) {
					alert(warnToAddress);
					res.result = false;
				} else if(typeof ra[0].id === 'undefined' ||
					ra[0].id == "") {
					alert(warnToAddress);
					res.result = false;
				} else if(this.state.currentReplyChannel == mechLINE.idstr) {
					// LINE channel only
					this.chckSocialCh(res, mechLINE);
				}
			} else if(this.state.currentReplyChannel == mechVK.idstr) {
				// VK channel only
				this.chckSocialCh(res, mechVK);
			} else if(this.state.currentReplyChannel == manualFBChannel.idstr) {
				// Facebook channel only
				this.chckSocialCh(res, manualFBChannel);
			} else if(this.state.currentReplyChannel == manualTwitter.idstr) {
				// Twitter channel only
				this.chckSocialCh(res, manualTwitter);
			}
		} else {
			if(typeof this.state.currentAreaData === "undefined" ||
				this.state.currentAreaData == null) {
				alert(warnArea);
				res.result = false;
			} else if(this.props.currentErrandData.data.service == Workflow.Errand.SERVICE_LINE ||
				this.props.currentErrandData.data.service == Workflow.Errand.SERVICE_MANUAL_LINE) {
				var warnLINETxt = I("Please enter a valid LINE MID to proceed.");
				if(!this.areaHasChannel(this.props.currentErrandData.data.service)) {
					this.warnAreaCh('LINE');
					res.result = false;
				} else if(!this.state.selectedReplyAddress.length ||
					this.state.selectedReplyAddress.length <= 0) {
					alert(warnLINETxt);
					res.result = false;
				} else if(typeof this.state.selectedReplyAddress[0].id === 'undefined' ||
					this.state.selectedReplyAddress[0].id == "") {
					alert(warnLINETxt);
					res.result = false;
				}
			} else if(this.props.currentErrandData.data.service == Workflow.Errand.SERVICE_VKONTAKTE ||
				this.props.currentErrandData.data.service == Workflow.Errand.SERVICE_MANUAL_VKONTAKTE) {
				if(!this.areaHasChannel(this.props.currentErrandData.data.service)) {
					this.warnAreaCh('VK');
					res.result = false;
				}
			}
		}
	},
	handleCancelTag: function(){
		this.setState({clickSendCaptured: false});
	},
	sendAnswer: function(sendAnswerToAll, sendCallback){
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			if(!this.warnEmptySubject()) {
				sendCallback(false);
				return
			}
		}
		var res = {};
		this.chckConditions(res, "send");
		if(!res.result) {
			sendCallback(false);
			return
		}
		ErrandHelper.sendAnswer({
			areaData:this.state.currentAreaData,
			areaDatas:this.state.currentAreaDatas,
			currentContextName: this.props.currentContextName,
			associated_list: this.state.associated_list,
			currentContext:this.props.currentErrandData,
			errandInfo:this.props.errandInfo,
			openedByExternal:this.props.openedByExternal,
			selected_normal_tags:[],
			translateToLang: this.state.translateToLang,
			translateFromLang: this.state.translateFromLang,
			forwarded: this.state.activateForwardExternal,
			onCancelTag: this.handleCancelTag
		},
		this.createUpdateErrandObject( sendAnswerToAll ),
		function( ret ){
			if(typeof ret != 'undefined' && !ret){
				sendCallback(false);
				return
			}
			sendCallback(true);
			if((typeof this.props.openedByExternal !== "undefined") &&
				(this.props.openedByExternal == true)){
				try {
					window.close();
				} catch (e) {
					console.log("dbg: unable to close browser window: ", e);
				}
				window.location.href = "about:blank";
			} else if( this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL )
				this.backToPreviousPage();
			else {
				this.resetManualErrandInput();
			}
		}.bind(this));
	},
	resetManualErrandInput: function() {
		if(this.refs.subject) this.refs.subject.SetValue("");
		this.signatureBodyContents = "";
		this.setState({
			selectedSalutations: 0,
			selectedSignature: 0,
			selectedReplyAddress: [],
			selectedReplyAddressCC: [],
			selectedReplyAddressBCC: [],
			selectedReplyAddressForwardExternal: [],
			uploadedAttachments: [],
			archiveAttachments: [],
			libraryAttachments: [],
			questionAttachments: [],
			selectedTags: [],
			clickSendCaptured: false,
			answerBody: ""
		});
		this.clearAnswer();
		if(typeof previewSalutation !== "undefined" && this.props.wfSettings.salutationPreviewContainer) {
			ReactDOM.unmountComponentAtNode(document.getElementById("previewSalutation"));
		}
		if(typeof previewSignature !== "undefined" && this.props.wfSettings.signaturePreviewContainer) {
			ReactDOM.unmountComponentAtNode(document.getElementById("previewSignature"));
		}
		ReactDOM.unmountComponentAtNode(document.getElementById("ErrandAttachment"));
	},
	packWithAreaFiltersForCounterUpdate: function (params){
		params.filterAreaNew = WorkflowStore.getState().ctx.newActiveAreas;
		params.filterAreaMy = WorkflowStore.getState().ctx.myActiveAreas;
		return params;
	},
	handleSendAnswer: function(e){
		this.setState({clickSendCaptured: true});
		this.sendAnswer(false, function callback(failOrSuccs){
			if(this.workflowSuccessfullyMounted) this.setState({clickSendCaptured: failOrSuccs});
		}.bind(this));
	},
	handleSendAnswerToAll: function(e){
		this.sendAnswer(true, function cb(){});
	},
	handleSaveAnswer: function(){
		if ((this.props.externalManual == true) &&
			(Object.keys(this.state.selectedReplyAddress).length == 0) ){
			this.state.selectedReplyAddress.push(
				{id:this.props.currentErrandData.data.fromAddress,
				value: this.props.currentErrandData.data.fromAddress});
		}
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			if(!this.warnEmptySubject()) {
				return
			}
		}
		var res = {};
		this.chckConditions(res, "save");
		if(!res.result) {
			return
		}
		ErrandHelper.updateAnswer({
			areaData:this.state.currentAreaData,
			areaDatas:this.state.currentAreaDatas,
			currentContextName: this.props.currentContextName,
			associated_list: this.state.associated_list,
			currentContext:this.props.currentErrandData,
			errandInfo:this.props.errandInfo,
			selected_normal_tags:[],
			translateToLang: this.state.translateToLang,
			translateFromLang: this.state.translateFromLang
		},
		this.createUpdateErrandObject( false ),
		function( message ){
			console.log("UpdateErrand(): " + message );
			if( ((typeof this.props.openedByExternal !== "undefined") &&
				(this.props.openedByExternal == true)) || (
				((typeof this.props.externalManual !== "undefined") &&
				(this.props.externalManual == true))) ){
				try {
					window.close();
				} catch (e) {
					console.log("dbg: unable to close browser window: ", e);
				}
				window.location.href = "about:blank";
			} else if( this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ){
				window.location.hash = ErrandHelper.getCacheBackTo();
			}
			else{
				$("#ErrandOwner > p").html(message);
				setTimeout(function() {
					window.location.hash = "#my";
				}, 3000);
			}
		}.bind(this));
	},
	handleOpenLibrary: function(){
		alert('not yet done');
	},
	handleReopenErrand: function(){
		ErrandHelper.reopenErrand(
		{update_id: this.props.currentErrandData.data.id,
			update_answer: this.state.answerBody,
			update_subject: this.refs.subject.GetValue()},
		function( message ){
			console.log("ReopenErrand(): " + message );
			if(this.props.currentContextName ==
				ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL &&
				this.props.externalManual == true &&
				this.props.currentErrandData.data.service ==
					Workflow.Errand.SERVICE_VOICE){
					open(location, '_self').close();
			} else {
				window.location.hash = ErrandHelper.getCacheBackTo();
			}
		}.bind(this));
	},
	handleResendErrand: function(){
		ErrandHelper.resendAnswer(
		this.createUpdateErrandObject( false ),
		function( message ){
			console.log("ResendErrand(): " + message );
			window.location.hash = ErrandHelper.getCacheBackTo();
		}.bind(this));
	},
	handleSuggestLibrary: function(){
		ErrandHelper.errandSuggestToLibrary(
			{errand: this.props.currentErrandData.data.id},
			function( message ){
				console.log("SuggestToLibrary(): " + message );
				window.location.hash = ErrandHelper.getCacheBackTo();
			}.bind(this)
		);
	},
	handleChangeArea: function(areas){
		this.state.areasForSearchAnswer = areas;
		this.setState(this.state);
	},
	handleChangeErrandArea: function(area) {
		if (document.getElementById('dueDate') != null) {
			var currentDueDate = document.getElementById('dueDate').innerText;
			this.state.errandDueDate = currentDueDate;
		}
		var errandId = this.props.currentErrandData.data.id;
		var areaId = area;
		var url = webserverRoot + 'errand/passiveChangeErrandArea';
		var data = {
			errand: errandId,
			area: areaId
		};
		$.post( url ,data )
		.then(function(datas) { //Mujibur: migration .success no more available in new version
			datas.mcam.channels.forEach(function(d) {
				if(typeof d.type != undefined && d.type == "Result" ) {
					var contents = JSON.parse(d.content);
					//FIXME Reset Answer Attachment
					return(
							ReactDOM.render(
								React.createElement(AgentAttachment,
									{	items: [],
										onChangeArea: true,
										onHandle: function(){}.bind(this)
									}
								), document.getElementById("ErrandAttachment")
							)
						);
				}
			}.bind(this));
		}.bind(this));
		$.when(this.fetchAreaData(area))
		.then(function(response) { // done
			var areaData;
			response.mcam.channels.forEach(function(d) {
				if(typeof d.type != undefined && d.type == "Result") {
					areaData = JSON.parse(d.content);
				}
			}.bind(this));
			if(areaData) {
				var channel = this.state.currentReplyChannel;
				if (channel == "") {
					channel = "1";
				}
				this.setState({
					currentReplyChannel: channel,
					areasForErrand: area, changeArea: true, visibleAttachmentBox: true,
					currentAreaData: areaData, currentAreaDatas: areaData,
					initiallyToggleDropdown: false, forceLoadedArea: true, selectedTags: []
				});
				this.renderAttachmentAdder();
			}
		}.bind(this), function(ad1) { // failed
			if(console) {
				console.debug("error load area answer:", ad1);
			}
		})
		var ckPreValue = this.state.answerBody;
		this._Cke("Answer").setData(ckPreValue);
	},
	handleChangeErrandChannel: function(channel) {
		this.setState({errorMessageSms: ""});
		this.setState({errorMessageCc: ""});
		this.setState({errorMessageTo: ""});
		this.setState({errorMessageBcc: ""});
		this.setState({errorMessageEe: ""});
		// this is just some 'protective' code - we should NOT modified
		// state but setState currentReplyChannel at this moment may caused
		// unintended behaviour to non-manual context. To avoid this the
		// setState only apply to manual context. TODO: just setState and no
		// modification to state.
		if(this.props.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			this.setState({currentReplyChannel: channel});
		} else {
			this.state.currentReplyChannel = channel;
		}
		this.fetchClientsAddress(this.props.currentErrandId, channel);
	},
	handleForwardToExternal: function() {
		var nxtActivate = !this.state.activateForwardExternal;
		var s = {activateForwardExternal: nxtActivate,
			visibleChangeRecepient: true};
		if(!nxtActivate) {
			s.selectedReplyAddressForwardExternal = [];
			this.props.onChckBoxChange('non-question-false');
		} else {
			this.props.onChckBoxChange('question', true);
			this.selectAllQuestionAttachments();
		}
		this.setState(s, function() {
			if(!this.state.activateForwardExternal) {
				$('.overlay').remove();
			} else {
				$('#HeaderContainer').after("<div class='overlay'></div>");
				$('#navbar').after("<div class='overlay'></div>");
				$('.overlay').css("display", "block");
			}
		}.bind(this));
	},
	handleChangeRecepientBox: function() {
		this.setState({visibleChangeRecepient: !this.state.visibleChangeRecepient})
	},
	handleExternalExpert: function(e){
		if(F("external-experts.open-window-immediately")){
			this.handleOpenExternalExpertPagePopup();
		} else {
			this.fetchExternalQueries(this.props.currentErrandId,e.clientX,e.clientY);
		}
	},
	handleOpenExternal: function( extSys ){
		var email = this.props.currentErrandData.data.fromAddress;
		var externalSystemUrls = extSys.externalSystemUrls;
		for(var i = 0; i < externalSystemUrls.length; i++){
			var url = externalSystemUrls[i].replace('[EMAIL]', email);
			if( url.length > 0 ){
				if(url.toLowerCase().indexOf("http://") == -1 && url.toLowerCase().indexOf("https://")  == -1) {
					url = 'http://' + url
				}
				var external = (extSys.howOpenExSysByAgent == 0 ?
					window.open(url, '_blank' + i) :
					window.open(url, 'external-system' + i, 'scrollbars=yes,menubar=yes,toolbar=yes,width=1024,height=768'));
				if(external) { // some time browser block the popup which will cause null value on external
					external.focus();
				} else {
					console.log("can not create window - browser blocked popup?");
				}
			}
		}
	},
	handleErrandNotesCounterUpdated: function(newCount){
		if(newCount != this.state.errandNotesCount){
			this.state.errandNotesCount = newCount;
			this.setState(this.state);
		}
	},
	handleErrandNotes: function(e){
		ErrandHelper.manageErrandNotes({x:e.clientX,y:e.clientY-200,errandId:this.props.currentErrandId,notesCounterUpdated:this.handleErrandNotesCounterUpdated});
	},
	handleCustNotesCounterUpdated: function(newCount){
		this.setState({custNotesCount:newCount});
	},
	handleClientContactUpdated: function(newCount){
		this.setState({clientContactCount:newCount});
	},
	handleErrandThreadUpdated: function(){
		this.changeKey = new Date().getTime();
	},
	handleContactCard: function(e){
		var fromAddress = this.props.currentErrandData.data.fromAddress;
		var fromName = this.props.currentErrandData.data.fromName;
		var service = this.props.currentErrandData.data.service;
		var dialogXPosition = e.clientX;
		var dialogYPosition = e.clientY-200;
		ErrandHelper.manageContactCard({
				x:dialogXPosition,
				y:dialogYPosition,
				errandId:this.props.currentErrandId,
				fromAddress:fromAddress,
				fromName:fromName,
				service:service,
				custNotesCounterUpdated:this.handleCustNotesCounterUpdated,
				handleErrandThreadsUpdated:this.handleErrandThreadUpdated,
				clientContactCounterUpdated:this.handleClientContactUpdated
			});
	},
	fetchClientsAddress: function(errandID, channelId) {
		if(this.props.currentContextName != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL) {
			var url = webserverRoot + "errand/fetchClientAddress";
			var data = {
				errand: errandID,
				email: channelId
			};
			$.post(url, data)
			.done(function(data) {
				data.mcam.channels.forEach(function(d) {
					if(typeof d.type != undefined && d.type == "Result") {
						var value = JSON.parse(d.content);
						this.refs.answerReplyOptions.handleStatus("disabled", value);
						this.refs.answerReplyOptions.handleServiceChange(value.service_type, '');
						/*Change channel should have to update To-Address as welll*/
						// this.state.selectedReplyAddress.length = 0;
						// this.state.selectedReplyAddress.push({id: value.email, value: value.email});
						var s = {};
						s.lockReplyTo = true;
						s.selectedReplyAddress = [{
							id: value.email, value: value.email}];
						switch (value.service_name.toLowerCase()){
							case "sms":
								s.currentErrandService = Workflow.Errand.SERVICE_SMS;
								s.currentErrandType = Workflow.Mail.TYPE_SMS;
								break;
							case "email":
								s.currentErrandService = Workflow.Errand.SERVICE_EMAIL;
								break;
							case "facebook":
								s.currentErrandService = Workflow.Errand.SERVICE_FACEBOOK;
								break;
							case "chat":
								s.currentErrandService = Workflow.Errand.SERVICE_CHAT;
								break;
							case "twitter":
								s.currentErrandService = Workflow.Errand.SERVICE_TWITTER;
								break;
							case "linkedin":
								s.currentErrandService = Workflow.Errand.SERVICE_LINKEDIN;
								break;
							default:
								s.currentErrandService = Workflow.Errand.SERVICE_EMAIL;
						}
						this.setState(s);
					}
				}.bind(this));
			}.bind(this));
		} else {
			var ces;
			$(".replyOptionsCheckbox").show();
			if( channelId == 1){
				// if( this.refs.answerReplyOptions != undefined )
				// 	this.refs.answerReplyOptions.handleServiceChange(Workflow.Errand.SERVICE_EMAIL, 'manual');
				ces = Workflow.Errand.SERVICE_MANUAL;
			}
			else if(channelId == 2){
				// if(this.refs.answerReplyOptions != undefined)
				// 	this.refs.answerReplyOptions.handleServiceChange(Workflow.Errand.SERVICE_FACEBOOK,  'manual');
				ces = Workflow.Errand.SERVICE_FACEBOOK;
				$(".replyOptionsCheckbox").hide();
			}
			else if(channelId == 3){
				// if( this.refs.answerReplyOptions != undefined )
				// 	this.refs.answerReplyOptions.handleServiceChange(Workflow.Errand.SERVICE_SMS, 'manual');
				ces = Workflow.Errand.SERVICE_SMS;
			}
			else if(channelId == 5) {
				// if( this.refs.answerReplyOptions != undefined )
				// 	this.refs.answerReplyOptions.handleServiceChange(Workflow.Errand.SERVICE_MANUAL_LINE, 'manual');
				ces = Workflow.Errand.SERVICE_MANUAL_LINE;
			}
			else{
				// TODO: add more future channel(s) here
				/*Some other channel will be added*/
			}
			if(typeof ces !== 'undefined') {
				this.setState({currentErrandService: ces});
			}
		}
	},
	backToPreviousPage: function() {
		if( features.pickUpNext ) {
			window.location.hash = ErrandHelper.getCacheBackTo() + "/next";
		} else {
			window.location.hash = ErrandHelper.getCacheBackTo();
		}
	},
	renderChooseArea: function(id, firstItem, areas){
		return(
		<div className="align-right">
			<DropDown
				id={id}
				selectedItems={firstItem}
				items={areas}
				fields={{id: "Id", name: "Name"}}
				name={I("Area")}
				textNoItem={I("No Area")}
				textChooseItem={I("Choose Area")}
				textNoItemSelected={I("No Area selected")}
				textSelectedItem={I("Selected Area")}
				nestedGroup="Areas"
				multiSelect={true}
				wantSelectAll={true}
				wantSelectNone={true}
				wantShowDone={true}
				align="right"
				onChange={this.handleChangeArea}
			/>
		</div>
		);
	},
	renderErrandChooseArea: function(id, className, title, firstItem, areas){
		let nestedGroup = F("agent.only-forward-to-area-in-organisation") ? "" : "Areas";
		if(firstItem == 0)
			firstItem = "";
		return(
			<DropDown
				id={id}
				title={title}
				selectedItems={firstItem}
				items={areas}
				fields={{id: "Id", name: "Name"}}
				name={I("Area")}
				textNoItem={I("No Area")}
				textChooseItem={I("Choose Area")}
				textNoItemSelected={I("No Area selected")}
				textSelectedItem={I("Selected Area")}
				nestedGroup={nestedGroup}
				multiSelect={false}
				wantSelectAll={false}
				wantSelectNone={false}
				singleSelect={true}
				align="left"
				onChange={this.handleChangeErrandArea}
				type="link"
				class={className}
			/>
		)
	},
	handleDropDownToggle :function(t) {
		this.setState({initiallyToggleDropdown: t});
	},
	renderErrandTagsSelection: function(newtaglist){
		if(newtaglist.length > 0){
			return (
				<DrilldownDropdown
					id="errandTaggedPicker"
					selectedItems={this.state.selectedTags}
					tags={newtaglist}
					initiallyToggleDropdown={this.state.initiallyToggleDropdown}
					inputTagClass='errandtags'
					updateSelectedItem={this.handleChangeQuickTags}
					updateDropDownToggle={this.handleDropDownToggle}
					height='400px'
					groupItems={true}
				/>
			)
		}else{
			return false;
		}
	},
	getExternalExpertAnswerAttachment: function() {
		if (typeof this.refs.exAttachment !== 'undefined'){
			var attachmentId = [];
			var domNode = ReactDOM.findDOMNode(this.refs.exAttachment);
			var tag = domNode.childNodes;
			if(tag.length > 0) {
				for (var i = 0; i < tag.length; i++) {
					if(tag[i].tagName.toLowerCase() == "span") {
						var span = tag[i].childNodes;
						if (span.length > 0) {
							for (var j = 0; j < span.length; j++) {
								if(span[j].tagName != undefined && span[j].tagName.toLowerCase() == "input") {
									if(span[j].checked) {
										attachmentId.push(span[j].value);
									}
								}
							}
						}
					}
				}
			}
			return attachmentId.join(',');
		}
	}
});
var ErrandAnswerFeatureActions = createReactClass({
	getInitialState: function(){
		return{
			publishSelected: false,
			closeSelected: false,
			addToLibrarySelected: false,
			lockMeSelected: false,
			highPrioritySelected: false,
			includeQuestionSelected: false,
			externalManual: false,
		}
	},
	componentWillMount: function(){
		var extendedData = this.props.extendedData.data;
		var wfSettings = this.props.wfSettings;
		var lockMeSelected = false;
		this.state.closeSelected = (wfSettings.closeCheckBoxDefaultState ? true : false);
		this.state.addToLibrarySelected = extendedData.errand_library;
		var errandLockToMe = extendedData.errand_lock;
		if( wfSettings.lockToMeCheckBoxDefaultState ){
			lockMeSelected = true;
			if( extendedData.errandAnswer ){
				lockMeSelected = errandLockToMe;
			}
		}else{
			if(errandLockToMe){
				lockMeSelected = true;
			}
		}
		this.state.includeQuestionSelected = false;
		this.setState({
			closeSelected: (wfSettings.closeCheckBoxDefaultState ? true : false),
			addToLibrarySelected: extendedData.errand_library,
			lockMeSelected: lockMeSelected,
			includeQuestionSelected: (F("toggleIncludeQuestionDefaultNo") ? false : true)
		});
		this.props.onErrandLock(lockMeSelected);
		this.props.onPartialAnswer(this.state.closeSelected);
		this.props.onErrandLibSuggest(this.state.addToLibrarySelected);
		this.props.onErrandIncQuestion(this.state.includeQuestionSelected, "default");
	},
	handleAdtoLibrary: function(event){
		this.state.addToLibrarySelected = !this.state.addToLibrarySelected;
		this.setState(this.state);
		this.props.onErrandLibSuggest(this.state.addToLibrarySelected);
	},
	handleClose: function(event){
		this.state.closeSelected = !this.state.closeSelected;
		this.state.partialAnswer = !this.state.partialAnswer;
		this.setState(this.state);
		this.props.onPartialAnswer(this.state.closeSelected);
	},
	handleLockToMe: function(event){
		this.state.lockMeSelected = !this.state.lockMeSelected;
		this.setState(this.state);
		this.props.onErrandLock(this.state.lockMeSelected);
	},
	handleIncQuestion: function(event){
		this.state.includeQuestionSelected = !this.state.includeQuestionSelected;
		this.setState(this.state);
		this.props.onErrandIncQuestion(this.state.includeQuestionSelected, "send");
	},
	generateCheckboxOptions: function(){
		var chkOptions = [];
		var wfSettings = this.props.wfSettings;
		var activeLibraryClass="";
		var activeLockClass="";
		var activeCloseSelected="";
		var activeIncludeQuestion="";

		if ((typeof this.props.externalManual !== "undefined") &&
			(this.props.externalManual == true)){
				return chkOptions;
		}
		if( wfSettings.centionLibrary ) {
			if(this.state.addToLibrarySelected) activeLibraryClass="active";
			chkOptions.push(<div key={3}><span><input type="checkbox" checked={this.state.addToLibrarySelected} onChange={this.handleAdtoLibrary} id="chkBoxAddtoLibrary" ref="chkBoxAddtoLibrary" /></span><span className={"icon-addtolib "+ activeLibraryClass} title={I("Suggest to Knowledge base")} data-toggle="tooltip"></span><span className="divider"> | </span></div>);
		}
		if( wfSettings.lockToMe ) {
			if(this.state.lockMeSelected) activeLockClass="active";
			chkOptions.push(<div key={4}><span><input type="checkbox" checked={this.state.lockMeSelected} onChange={this.handleLockToMe} id="chkBoxLock" ref="chkBoxLock" /></span><span className={"icon-locktome "+activeLockClass} title={I("Lock to me")} data-toggle="tooltip"></span><span className="divider"> | </span></div>);
		}

		if(F('toggleIncludeQuestion') && this.props.bttns.incQuestion) {
			var incQstyle = {display: "inline-block"};
			if(this.props.extFwdActive) {
				incQstyle = {display: "none"};
			}
			if(this.props.includeQ.status) activeIncludeQuestion="active";
			chkOptions.push(<div key={5} id="includeQuestionFeature" style={incQstyle}>
				<span><input type="checkbox" checked={this.props.includeQ.status}
					onChange={this.props.includeQ.handle} id="chkBoxQuestion"
					ref="chkBoxQuestion" /></span>
				<span className={"icon-incquestion "+activeIncludeQuestion}
					title={I("Include Question")} data-toggle="tooltip">
				</span><span className="divider"> | </span></div>);
		}
		if( this.props.bttns.close ) {
			if(this.state.closeSelected) activeCloseSelected="active";
			chkOptions.push(<div key={2}><span><input type="checkbox" checked={this.state.closeSelected} onChange={this.handleClose} id="chkBoxClose" ref="chkBoxClose" /></span><span className={"linkPartial "+activeCloseSelected}>{I('Partial answer')}</span></div>);
		}
		return chkOptions;
	},
	render: function(){
		return(
			<span className="replyOptionsCheckbox">
			{this.generateCheckboxOptions()}
			</span>
		)
	}
});

var ErrandQuestionAttchments = createReactClass({
	linkChildCheckbox: function(e) {
		this.props.onSelectedAttach(e.target.checked, 'single', e.target.value);
	},
	linkGlobalCheckbox: function(e) {
		this.props.onSelectedAttach(e.target.checked, 'all');
	},
	renderSizeAccordingly: function(showSize) {
		if(showSize < 1000000)
			showSize = "("+(showSize/1000).toFixed(2)+" kb)";
		else
			showSize = "("+(showSize/1000000).toFixed(2)+" mb)";
		return showSize;
	},
	render: function() {
		var c = [], allChecked = true;
		$.each(this.props.attachments, function(i,d) {
			var selected = false;
			$.each(this.props.selectedAttachments, function(i,v) {
				if(d.id == v) {
					selected = true;
					return false;
				}
			});
			if(allChecked && !selected) {
				allChecked = false;
			}
			c.push(<div key={d.id}>
				<input type="checkbox" checked={selected} value={d.id}
					onChange={this.linkChildCheckbox} />
				<span dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(d.value)}} />&nbsp;
				{this.renderSizeAccordingly(d.estSize)}<br />
			</div>);
		}.bind(this));
		return (
			<div>
				<input type="checkbox" checked={allChecked}
					onChange={this.linkGlobalCheckbox} />
				<span>{I("Add all customer attachments")}</span><br />
				{c}
			</div>
		);
	},
});

var PreviewSalutation = createReactClass({
	render: function(){
		return(<div className="col-md-12 previewErrandSalutation"><div dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(this.props.salutationContent)}} /></div>)
	}
});

var PreviewSignature = createReactClass({
	render: function(){
		return(<div className="col-md-12 previewErrandSignature"><div dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(this.props.signatureContent)}} /></div>);
	}
});

var AddNewAttachmentBox = createReactClass({
	render: function(){
		var areaFileArchive = (this.props.areaFileArchive? this.props.areaFileArchive : [])
		if(!this.props.visibleAttachmentBox)
			return(<span></span>);

		return(
			<div id="AttachFiles">
				<div className="attachFileArchive">
					<DropDown
						id={"AnswerFileArchiveAttach"}
						ref={"AnswerFileArchiveAttach"}
						name={I("archive")}
						textNoItem={I("No archive")}
						textChooseItem={I("Choose archive")}
						textNoItemSelected={I("No archive selected")}
						textSelectedItem={I("Selected archive")}
						items={areaFileArchive}
						fields={{id: "id", name: "value"}}
						onChange={this.handleArchiveAttachment}
					/>
				</div>
				<div className="attachFileBrowse">
					<FileUploader uploadTo="errand/uploadAnswerAttachment"  attachFile={this.props.wfSettings.agentAttachFile} multipleFile={this.props.wfSettings.agentAttachMultipleFile} onDeleteUpload={this.handleDeleteUpload} onProgress={this.handleUploadProgress} onFileupload={this.handleFileUpload} />
				</div>
			</div>
		);
	},
	handleArchiveAttachment: function( selectedItem ){
		this.props.areaFileArchive.forEach(function( attachment ){
			if( attachment.id === selectedItem ){
				this.props.onArchiveAttachFinish(attachment, 'add');
				return(
					ReactDOM.render(
						React.createElement(AgentAttachment,
							{	items: attachment,
								onHandle: function( attachment ){
									this.props.onArchiveAttachFinish(attachment.id, 'del');
								}.bind(this)
							}
						), document.getElementById("ErrandAttachment")
					)
				);
			}
		}.bind(this));
	},
	handleDeleteUpload: function( fid ){
		this.props.onUploadFinish(fid, 'del');
	},
	handleFileUpload: function( fid ){
		this.props.onUploadFinish(fid, 'add');
		document.getElementById("LoadingAttachment").style.display = 'none';
		ReactDOM.render(
			React.createElement(AgentAttachment,{
					items: fid,
					onHandle: function( attachment ){
						this.handleDeleteUpload( attachment );
						$.post( webserverRoot + "errand/removeTempAttachment", {fid: attachment.id});
					}.bind(this)
				}
			), document.getElementById("ErrandAttachment")
		);
	},
	handleUploadProgress: function( value ){
		document.getElementById("LoadingAttachment").style.display = 'block';
		ReactDOM.render(<div><img src="img/loading-attachment.gif" /></div>, document.getElementById("LoadingAttachment"));
	}
});

var AttachmentLink = createReactClass({
	handleClick: function(e){
		this.props.onClick( this );
	},
	render: function(){
		var channel = this.props.channel;
		if(channel === Workflow.Errand.SERVICE_TWITTER ){
			if( this.props.isTwitter ){
				return(<span className="clickToShowService icon-attachment" title={I("Attach files")}>
						<div className="serviceUnavailable">{I("It's not possible to add attachments for twitter direct message answers.")}</div>
					</span>);
			}
		}
		return(<span className="icon-attachment" title={I("Attach files")} onClick={this.handleClick}></span>);
	}
});

var Errand = createReactClass({
	getInitialState: function() {
		return {
			areaData: {},
			ErrandInfo: {
				acquireErrand: {},
				extendedData: {},
				searchErrandsData: {},
				manualChannel: 0
			},
			workflowSettings: {},
			ctx: WorkflowStore.getState().ctx,
			OnlyShowIfAcquired: false,
			currentContextName: ErrandHelper.ERRAND_SRC_CONTEXT_NEW,
			currentContextType: '',
			// special state to indicate errand data in ErrandForm need
			// reload. This state can be used by others also to update
			// components inside ErrandForm and mainly used for uncontrolled
			// components. ErrandForm componentWillReceiveProps should check
			// the appropriate props and update the uncontrolled component
			// respectively. However, the better solution is still using
			// control component and all this stupid hack can be avoided. A
			// lot of states in ErrandForm need to be pulled back into
			// Errand component if we going for control component especially
			// the area data. But one benefit of uncontrolled component is
			// high speed render component such as text input can benefit
			// this because without this every single characters change
			// React will call ErrandForm render which is a hugh logic
			// coding that can slow the typing process.
			newErrandData: {},
			historyData: [],
			qhistoryCheckboxes: this.initCheckboxes()
		}
	},
	workflowSettings: {},
	errandLists: [],
	currentErrandId: 0,
	currentErrandData: {},
	attemptsToAcquire: 0,
	errandNotesCount: 0,
	componentWillMount: function(){
		WorkflowActions.updateContext(ErrandHelper.ERRAND_SRC_CONTEXT_ANSWER);
		WorkflowStore.listen(this.onFluxStoreChange);
		if( this.props.errandSrc != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL ){
			this.currentErrandId = parseInt((window.location.hash.split('#')[1]).split('/')[1]);
			this.fetchWFSettingsFroCache();
			this.state.currentContextName = this.state.ctx.current;
			if( Object.keys(this.state.workflowSettings).length > 0 )
				this.fetchAreaDataByErrand( this.currentErrandId );
			else
				window.location.hash = ErrandHelper.getCacheBackTo();
		}else{
			this.state.currentContextName = ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL;
			this.state.currentContextType = this.props.errandContextType;
			this.currentErrandId = this.props.errandId;
			this.fetchWFSettingsFroCache();
			if((this.props.externalManual == true) &&
				(this.currentErrandId > 0)){
				this.fetchAreaDataByErrand(this.currentErrandId);
			}
			this.manualErrandAreaSetup();
		}
	},
	componentWillReceiveProps: function(nextProps) {
		//@Sany: Added the componentWillReceiveProps to escape non-reasonbly using reactDOM
		//Still need to monitor if something we need to improve
		if (nextProps.errandSrc !== this.props.errandSrc || nextProps.errandContextType !== this.props.errandContextType) {
			this.currentErrandId = nextProps.errandId;
			this.fetchWFSettingsFroCache();
			if((this.props.externalManual == true) &&
				(this.currentErrandId > 0)){
				this.fetchAreaDataByErrand(this.currentErrandId);
			}
			this.manualErrandAreaSetup();
			this.setState({ currentContextName: nextProps.errandSrc, currentContextType: nextProps.errandContextType});
		}
		if(nextProps.errandSrc != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL && nextProps.errandId !== this.props.errandId){
			this.currentErrandId = parseInt(nextProps.errandId);
			this.fetchWFSettingsFroCache();
			this.fetchAreaDataByErrand( this.currentErrandId );
		}
		if(nextProps.errandSrc == ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL && nextProps.errandContextType == "Manual") {
			localStorage.removeItem("searchState");
		}
	},
	componentWillUnmount: function() {
		WorkflowStore.unlisten(this.onFluxStoreChange);
	},
	onFluxStoreChange: function(state) {
		this.state.ctx = state.ctx;
		this.setState(this.state);
	},
	getErrandList: function( datas ){
		var errandList = [];
		datas.mcam.channels.forEach(function(d){
			if(typeof d.content != undefined && d.content != ""){
				var contents = JSON.parse(d.content);
				if( contents.errands != undefined){
					errandList = contents.errands.list;
				}
			}
		}.bind(this));
		return errandList;
	},
	fetchWFSettingsFroCache: function (){
		var jsonStr = localStorage.getItem("WorkflowSettings");
		if(jsonStr !== 'undefined' && jsonStr !== null){
			var settings = JSON.parse( jsonStr );
			this.state.workflowSettings = settings;
			this.setState( this.state );
		}
	},
	loadAreaData: function(url, data){
		return $.post( url , data);
	},
	maybeShowErrors: function(eid, d) {
		if(d.email_size_exceeded) {
			setTimeout(function() {
				alert("Errand " + eid + " encountered error 'email limit size exceeded' during delivery.");
			}, 0);
		}
		if (d.has_error_reported) {
			setTimeout(function() {
				var msg = I("Errand {ERRAND_ID} encountered error during delivery. Check its actions to see the error details.")
					.replace('{ERRAND_ID}', eid)
					;
				alert(msg);
			}, 0);
		}
	},
	doActualFetchAreaData: function(errand) {
		var eid = errand.id
		var fromAddress = errand.data.fromAddress;
		var service = errand.data.service;
		var url = webserverRoot + 'errand/areaData';
		var data = {}
		if (typeof errand.data != 'undefined'){
			data = {areas: errand.data.area};
		} else {
			data = {areas: errand.area};
		}
		this.attemptsToAcquire++;
		$.when(
			this.loadAreaData(url, data),
			this.acquiredErrand(eid),
			this.fetchExtendedData(eid),
			this.getChannelList(eid),
			this.fetchHistory(eid)
		).then(function(
			areaJson,
			acquireErrandJson,
			extendedDataJson,
			channelListJson,
			historyJson
		) {
			var areaData = "[{}]";
			var acquireErrandData = "[{}]";
			var extendedData = "[{}]";
			var channelList = "[{}]";
			areaJson[0].mcam.channels.forEach(function(d) {
				if (typeof d.type != undefined && d.type == "Result") {
					areaData = JSON.parse(d.content);
					this.state.areaData = areaData;
				}
			}.bind(this));
			acquireErrandJson[0].mcam.channels.forEach(function(d) {
				if (typeof d.type != undefined && d.type == "Result") {
					acquireErrandData = JSON.parse(d.content);
				}
			});
			if (!acquireErrandData.acquired) {
				if (this.state.workflowSettings.onlyShowErrandIfAcquired) {
					this.state.OnlyShowIfAcquired = true;
				}
			}
			extendedDataJson[0].mcam.channels.forEach(function(d) {
				if (typeof d.type != undefined && d.type == "Result") {
					extendedData = JSON.parse(d.content);
					if(typeof extendedData.data !== 'undefined' &&
						extendedData.data != null &&
						typeof extendedData.data.errand_notes !== 'undefined'){
						this.errandNotesCount = extendedData.data.errand_notes;
					}
				}
			}.bind(this));
			channelListJson[0].mcam.channels.forEach(function(d) {
				if (typeof d.type != undefined && d.type == "Result") {
					channelList = d.content;
					this.state.channelList = channelList;
				}
			}.bind(this));
			var historyData = historyJson[0].data;
			//MCAM data needs to set to component for dom loading
			if (acquireErrandData.error) {
				if (this.attemptsToAcquire <= 3) {
					this.fetchAreaDataByErrand(this.currentErrandId);
				} else {
					var state = this.state;
					state.acquireErrandError = true;
					state.acquireErrandErrorMessage = acquireErrandData.error;
					this.setState(state);
				}
			} else {
				this.maybeShowErrors(eid, extendedData.data);
				this.setState({
					areaData: areaData,
					ErrandInfo:
					{
						acquireErrand: acquireErrandData,
						extendedData: extendedData
					},
					channelList: channelList,
					newErrandData: {},
					historyData: historyData,
				});
				if (ErrandHelper.updateErrandCounter) {
					ErrandHelper.updateErrandCounter(acquireErrandJson[0]);
				}
			}
		}.bind(this));
	},
	fetchErrandFromLocalStorage: function( errandId ){
		var jsonObj = null;
		var errandObject = {};
		try{
			jsonObj = sessionStorage.getItem("errandRawSrcList");
			jsonObj.data.id = errandId;
		}catch(e){
			return errandObject;
		}
		if( (jsonObj !== null) && (jsonObj !== undefined )){
			errandObject = JSON.parse( jsonObj );
			if (errandObject.id && errandObject.id == errandId) {
				this.currentErrandData = errandObject;
			} else {
				this.currentErrandData = {};
				errandObject = {};
			}
			//FIXME(Mujibur): We should not keep the ticker data to localstorage
			// if ( Object.keys( errandObject ).length == 0){
			// 	this.currentErrandData = JSON.parse(localStorage.getItem("errandTickerList"));
			// 	errandObject = JSON.parse(localStorage.getItem("errandTickerList"));
			// }
		}
		return errandObject;
	},
	fetchErrandFluxStore: function(eid){
		var errandObject = {};
		var errandSrc = this.state.ctx.errandRawSrcList;
		if( Object.keys(errandSrc).length > 0){
			var lists = errandSrc.list;
			for(var items in lists){
				if(lists.hasOwnProperty(items)){
					if(lists[items].id == eid){
						this.currentErrandData = lists[items];
						errandObject = lists[items];
						break;
					}
				}
			};
		}
		return errandObject;
	},
	fetchAreaDataByErrand: function( errandId ){
		var errand = this.fetchErrandFluxStore(errandId);
		sessionStorage.setItem("answerOpenedFromSearch", "false");
		if( $.isEmptyObject(errand) == false ){
			try{
				sessionStorage.setItem("errandRawSrcList", JSON.stringify( errand ));
			}catch(e){
				errand = {};
			}
		}else{
			errand = this.fetchErrandFromLocalStorage( errandId );
		}
		if (($.isEmptyObject(errand) == false) &&
			((typeof this.props.externalManual === 'undefined') ||
				(this.props.externalManual == false))) {
			WorkflowStore.getState().ctx.acquireErrand.owner.id = errand.data.agentId;
			if(this.state.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_SEARCH)
				sessionStorage.setItem("answerOpenedFromSearch", "true");
			this.doActualFetchAreaData(errand);
		} else{
			var url = webserverRoot + 'errand/basic/' + errandId;
			this.loadBasicErrand(url, null)
			.then(function(basicErrand) {
				if ($.isEmptyObject(basicErrand) || jQuery.isEmptyObject(basicErrand.data)) {
					window.location.hash = 'search';
				} else {
					if (this.currentErrandData == null) {
						this.currentErrandData = {};
					}
					this.currentErrandId = basicErrand.id;
					this.currentErrandData = basicErrand;
					this.doActualFetchAreaData(basicErrand);
				}
			}.bind(this));
		}
	},
	loadBasicErrand: function(url, data){
		return $.post( url , data);
	},
	acquiredErrand: function( errandId, context ){
		var url = webserverRoot + 'errand/acquire';
		var areaIds = this.getAreaIds();
		var data = {
			errand: errandId,
			fetch_data_only_if_acquired: (this.state.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_NEW ? true : false),
			errand_was_opened_by_external_system: (this.props.openedByExternal ? true : false),
			source: (context ? context : this.state.currentContextName),
			filterList: areaIds.join( ',' )
		};
		return $.post(url, data);
	},
	fetchExtendedData: function( errandId ){
		var url = webserverRoot + 'errand/extendedData';
		var data = {
			errand: errandId,
			openedByExternal: this.props.openedByExternal
		};
		return $.post(url, data);
	},
	getChannelList: function(eid){
		var url = webserverRoot + 'errand/fetchClientsAddressList';
		var data = {
			errandId: eid
		};
		return $.post(url, data);
	},
	getAreaIds: function(){
		var areaList = [];
		var workflowAreaData = [];
		if( Object.keys(this.state.workflowSettings).length > 0){
			this.state.workflowSettings.areas.forEach(function( d ){
				if(d.Areas != undefined){
					d.Areas.forEach(function( a ){
						areaList.push(a.Id);
					}.bind(this));
				}
			}.bind(this));
			return areaList;
		}
	},
	handleErrandAction: function( errand, context ){
		this.currentErrandId = errand.id;
		this.currentErrandData = errand;
		var url = webserverRoot + 'errand/areaData';
		var data = {areas: errand.data.area};
		$.when(
			this.loadAreaData(url, data),
			this.acquiredErrand(this.currentErrandId, context),
			this.fetchExtendedData(this.currentErrandId),
			this.fetchHistory(this.currentErrandId)
		)
		.then(function(
			areaJson,
			acquireErrandJson,
			extendedDataJson,
			historyJson
		){
			var areaData = "[{}]";
			var acquireErrandData = "[{}]";
			var extendedData = "[{}]";
			var historyData = "[{}]";
			areaJson[0].mcam.channels.forEach(function( d ){
				if(typeof d.type != undefined && d.type == "Result" ){
					areaData = JSON.parse(d.content);
					this.state.areaData = areaData;
				}
			}.bind(this));
			acquireErrandJson[0].mcam.channels.forEach(function( d ){
				if(typeof d.type != undefined && d.type == "Result" ){
					acquireErrandData = JSON.parse(d.content);
				}
			});
			extendedDataJson[0].mcam.channels.forEach(function( d ){
				if(typeof d.type != undefined && d.type == "Result" ){
					extendedData = JSON.parse(d.content);
				}
			});
			historyData = historyJson[0].data;
			this.setState({
				areaData: areaData,
				ErrandInfo:
				{
					acquireErrand: acquireErrandData,
					extendedData: extendedData
				},
				newErrandData: {},
				historyData: historyData,
			});
		}.bind(this));
	},
	fetchHistory: function( eid ){
		var url = webserverRoot + 'errand/history';
		return $.post(url, {
		errand: eid,
		openedByExternal: this.props.openedByExternal });
	},
	acqriedErrandByAgent: function(){
		var agentName = (this.state.ErrandInfo.acquireErrand && this.state.ErrandInfo.acquireErrand.owner ? this.state.ErrandInfo.acquireErrand.owner.name : "");
		var msg = I('The errand is already taken by agent {0}').format(agentName);
		$( "#ErrandAlreadyTakenBySomeone" ).html( msg );
		$( "#ErrandAlreadyTakenBySomeone" ).attr('title', I('Errand already taken'));
		$( "#ErrandAlreadyTakenBySomeone" ).dialog({
				width: 500,
				modal: true
		});
		window.location.hash = ErrandHelper.getCacheBackTo();
	},
	showAcquireErrandError: function() {
		$('#ErrandAlreadyTakenBySomeone').html(this.state.acquireErrandErrorMessage);
		$('#ErrandAlreadyTakenBySomeone').attr('title', I('A problem occurred when acquiring the errand'));
		$('#ErrandAlreadyTakenBySomeone').dialog({
				width: 500,
				modal: true
		});
		window.location.hash = ErrandHelper.getCacheBackTo();
	},
	manualErrandAreaSetup: function(){
		var meState = {ErrandInfo: {}, newErrandData: {}};
		if (this.props.externalManual == false ){
			meState.areaData = {
				addressbook_addresses: [],
				agents: [],
				archive_size: [],
				user_saluatation_pref: "",
				user_signature_pref: "",
				area_salutation_pref: "",
				area_signature_pref: ""
			}
			this.currentErrandData = {
				id: 0,
				threadId: 0,
				data:{
					body: "",
					answer_mail_attachments: [],
					closed: false,
					deleted: false,
					fromAddress: "",
					fromName: "",
					area: 0,
					id: 0
				}
			};
		}
		meState.ErrandInfo.acquireErrand = {
			errand: 0,
			data: {
				answer_body: "",
				answer_subject: "",
				answer_alternatives: [],
				answer_alternatives_payload: []
			}
		};
		meState.ErrandInfo.extendedData = {
			data:{
				answer_bcc: "",
				answer_cc: "",
				answer_to: "",
				answer_salutation: 0,
				answer_signature: 0
			}
		};
		meState.historyData = [];
		this.setState(meState);
	},
	initCheckboxes: function() {
		var c = {};
		c.all = false;
		if(!F('toggleIncludeQuestionDefaultNo')) {
			c.question = true;
			c.answerquestion = true;
		}
		return c;
	},
	handleCheckboxes: function(t, checkStatus) {
		var c = {};
		if(t === 'non-question-false') {
			// special command to set all non-question checkbox to false
			c.all = false;
			if(typeof this.state.qhistoryCheckboxes.answerquestion !== 'undefined') {
				c.answerquestion = this.state.qhistoryCheckboxes.answerquestion;
			}
			this.setState({qhistoryCheckboxes: c});
			return;
		}
		if(this.currentErrandId > 0) {
			var self = false;
			var typ = typeof t;
			if(typ === 'number' && this.currentErrandId == t) {
				self = true;
			} else if(typ == 'string') {
				if(t === 'question') {
					self = true;
				} else if(this.currentErrandId.toString() === t) {
					self = true;
				}
			}
			if(self) {
				c.question = checkStatus;
				this.setState({qhistoryCheckboxes:
					update(this.state.qhistoryCheckboxes, {
						question: {$set: checkStatus}
					})});
				return;
			}
		}
		c[t] = checkStatus;
		this.setState({qhistoryCheckboxes:
			update(this.state.qhistoryCheckboxes, {$merge: c})});
	},
	render: function(){
		if( Object.keys(this.state.workflowSettings).length <= 0 )
			return <div></div>;

		if (this.state.acquireErrandError) {
			this.showAcquireErrandError();
			return <div></div>
		}
		if( this.state.OnlyShowIfAcquired && this.state.currentContextName == ErrandHelper.ERRAND_SRC_CONTEXT_NEW ){
			this.acqriedErrandByAgent();
			return <div></div>;
		}
		if( this.props.errandSrc != ErrandHelper.ERRAND_SRC_CONTEXT_MANUAL && $.isEmptyObject(this.currentErrandData) ){
			return <div></div>;
		}

		if(this.state.ErrandInfo.extendedData.data != undefined){
			var thisAnswered = this.state.ErrandInfo.extendedData.data.answered;
			var thisUnanswered = this.state.ErrandInfo.extendedData.data.unanswered;
			//remove current errand from the count
			if(typeof this.currentErrandData.data !== "undefined"){
				if (this.currentErrandData.data.answered == false){
					thisUnanswered--;
				} else {
					thisAnswered--;
				}
			}
			var clientContactDetails = {
				errand_client_contacts : this.state.ErrandInfo.extendedData.data.errand_client_contacts,
				answered : this.state.ErrandInfo.extendedData.data.answered,
				unanswered : this.state.ErrandInfo.extendedData.data.unanswered
			}
		}
		return(<div key="ErrandForm">
				<ErrandForm
					areaData={this.state.areaData}
					errandInfo={this.state.ErrandInfo}
					wfSettings={this.state.workflowSettings}
					currentContextName={this.state.currentContextName}
					currentErrandData={this.currentErrandData}
					currentErrandId={this.currentErrandId}
					onHandleErrandActon={this.handleErrandAction}
					channelList={this.state.channelList}
					currentContextType={this.state.currentContextType}
					clientContactDetails={clientContactDetails}
					externalManual={this.props.externalManual}
					openedByExternal={this.props.openedByExternal}
					isClearIT={this.props.isClearIT}
					newErrandData={this.state.newErrandData}
					historyData={this.state.historyData}
					qhistoryCheckboxes={this.state.qhistoryCheckboxes}
					onChckBoxChange={this.handleCheckboxes}
					errandNotesCount={this.errandNotesCount}
				/>
				<div id="ErrandActionsTagging"></div>
				<div id="errand_action_modal_container"></div>
				<div id="dialogPrintSelected" style={{display: 'none'}}></div>
				<div id="ErrandAlreadyTakenBySomeone"></div>
			</div>
		)
	}
});
export default Errand;
